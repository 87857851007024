export const lang_en = {
    "SUBMIT" : "Submit",
    "MAIN" : "Main",
    "MAIN_MENU" : "Main Menu",
    "RESTART_SESSION" : "Restart Session",
    "END": "End Session",
    "TYPE_A_MESSAGE": "Type your message here",
    "CONFIRM": "Confirm",
    "ENTER_NAME": "Name",
    "DATE_OF_BIRTH": "Date of Birth",
    "DATE": "Please enter date",
    "MOBILE_NUMBER": "Mobile Number",
    "EMAIL_ID": "Email ID",
    "DD": "DD",
    "MM": "MM",
    "YYYY": "YYYY",
    "IS_TYPING":"is typing",
    "RESEND": "Resend",
    "SELECT": "Select",
    "AHMED_TYPING" : "Ahmed is typing",
    "SARA_TYPING": "Sara is typing",
    "LIVE_AGENT": "Live Agent",
    // "ENTER_PAST_DATE": "Please enter past date",
    // "ENTER_FUTURE_DATE": "Please enter future date",
};