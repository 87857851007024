import React from "react";
import _ from "lodash";
import PoweredBy from './../PoweredBy/PoweredBy';
import botAvatar from "./../../public/images/bot-avatar-Suhail.png";
import {ENVIORMENT} from './../../env';

class LayoutOne extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAvatar: "Suhail"      
    };
  }
  componentDidMount() {}
  componentDidUpdate() {}

  startConversation(lang = "English") {
    if (_.get(this, "state.selectedAvatar", "") !== "") {
      this.props.startConversation(
        this.state.selectedAvatar, 
        (lang === "English" ? "Suhail" : "سهيل"),
      lang);
    }    
  }

  render() {
    return (
      <div className={"start-converstion"}>
        <div className="container">
          <div className="row">
            <div className="col position-relative avatar-container">
              <div className="row">
              <div className="cover-pattern-b">
                    <div className="pl-4 pr-4 pt-4 pb-4">
                      <h3>
                        <img src={_.get(this, "props.welcometxt", "")} alt="" />
                      </h3>
                      <h3 className="pb-2">Hello, I’m Suhail,<br/>your virtual assistant!</h3>
                    </div>  
                    <div className="user-profile-b">
                      <div className="user-profile-male-b">
                        <span>
                          <img src={botAvatar} alt="" />
                        </span>
                        <span className="status-online-b"> </span>
                        <h5>سهيل</h5>
                        <h5>{_.get(this, "state.selectedAvatar", "")}</h5>
                      </div>
                    </div>
                </div>
              </div>
            </div>
            <div className="white-pattern-b text-container">
              <div className="body-text-b p-5">
                <p>
                  <img src={_.get(this, "props.Municipalitytxt", "")} alt="" className="img-fluid pb-1"/>
                  Welcome to Al Ain Municipality
                </p>
              </div>
              
              <div className="col body-text-b pt-2 pb-0">
                <button
                  className="btn col-6 btn-start"
                  onClick={ () => this.startConversation("English") }
                >
                  Let’s Talk
                </button>

                <button
                  className="btn col-6 btn-start"
                  onClick={ () => this.startConversation("Arabic") }
                >
                  دعنا نتحدث!
                </button>

                <div className="term-text-b">
                <p>
                  By clicking on ‘Let’s Talk’, you agree to our{" "}
                  <a href={ENVIORMENT.policy_url} target="_blank" rel="noopener noreferrer">Terms and Conditions</a>
                </p>
                <p>
                إذا اخترت المتابعة فإنك
                تؤكد موافقتك على الالتزام 
                <a href={ENVIORMENT.policy_url} target="_blank" rel="noopener noreferrer">
                الشروط والأحكام
                </a> بهذه
                </p>
              </div>
              </div>
            </div>
            <PoweredBy className="powerd-by-container"></PoweredBy>
          </div>
        </div>
      </div>
    );
  }
}
export default LayoutOne;