import React, { Component } from "react";
import { ENVIORMENT } from "./../../env";
import $ from "jquery";

import "./carousel.scss";
import _ from "lodash";
import { guid } from "./../utils/Utility";

class CarouselLeftArrow extends Component {
  render() {
    return (
      <button
        className={
          "carousel__arrow carousel__arrow--left " +
          (this.props.active === true ? "active" : "")
        }
        onClick={this.props.onClick}
      >
        <span className="fa fa-2x fa-angle-left" />
      </button>
    );
  }
}

class CarouselRightArrow extends Component {
  render() {
    return (
      <button
        className={
          "carousel__arrow carousel__arrow--right " +
          (this.props.active === true ? "active" : "")
        }
        onClick={this.props.onClick}
      >
        <span className="fa fa-2x fa-angle-right" />
      </button>
    );
  }
}

class CarouselIndicator extends Component {
  render() {
    return (
      <li>
        <button
          disabled={_.get(this, "props.disablebutton", true) ? "disabled" : ""}
          className={
            this.props.index === this.props.activeIndex
              ? "carousel__indicator carousel__indicator--active"
              : "carousel__indicator"
          }
          onClick={this.props.onClick}
        >
          {" "}
        </button>
      </li>
    );
  }
}

class CarouselSlide extends Component {
  project;
  constructor(props) {
    super(props);
    this.slideClick = this.slideClick.bind(this);
    this.project = ENVIORMENT.project;
  }
  slideClick(selectedOption) {
    this.props.CarouselSelect(selectedOption);
  }
  render() {
    const bgImg = _.get(this, "props.slide.image", "");
    let bImg;
    try {
      bImg = require(`./../../public/images/slider-menu/${this.project}/${_.get(
        this,
        "props.slide.image",
        ""
      )}`);
    } catch (err) {
      bImg = require(`./../../public/images/slider-menu/${this.project}/CarouselBlankMedcare.jpg`);
    }


    const test = Math.ceil(this.props.carousel_id);
    const finalID = test.toString();
    
    

    return (
      <li
        className={
          this.props.index === this.props.activeIndex
            ? "carousel__slide carousel__slide--active"
            : "carousel__slide"
        }
      >
        <div className="card slider-bot" data-id={`${finalID}`}>
          {_.get(this, "props.slide.image_type", "") === "name" && (
            <div className="card-img">
              <img className="card-img-top" src={bImg} alt="" />
            </div>
          )}
          {_.get(this, "props.slide.image_type", "") !== "name" && (
            <div
              className="card-img"
              style={{
                backgroundImage: 'url("' + bgImg + '")'
              }}
            ></div>
          )}

          <div className="card-body">
            <div className="card-header">
              <h4
                className={
                  "card-title mt-2 mb-0" +
                  (_.get(this, "props.isRTLFlow", false) &&
                  _.get(this, "props.chat.font", "") !== "neutral"
                    ? " font-arabic-light "
                    : "")
                }
              >
                {_.get(this, "props.slide.label", "")}
              </h4>
              {_.get(this, "props.slide.description", "") !== "" && (
                <span
                  className={
                    _.get(this, "props.isRTLFlow", false) &&
                    _.get(this, "props.is_font_neutral", false) === false
                      ? "bot-desc font-arabic-light "
                      : "bot-desc"
                  }
                >
                  {_.get(this, "props.slide.description", "")}
                </span>
              )}
            </div>

            <SlideOption
              disablebutton={_.get(this, "props.disablebutton", false)}
              slideOption={_.get(this, "props.slide.buttons")}
              slideClick={this.slideClick}
              isOptionSelected={_.get(this, "props.isOptionSelected", false)}
              isRTLFlow={_.get(this, "props.isRTLFlow", false)}
              lang={_.get(this, "props.lang", "English")}
            />
          </div>
        </div>
      </li>
    );
  }
}

class SlideOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: false,
      disablebutton: false
    };
  }
  selectedOption(option) {
    if (
      _.get(this, "state.selectedOption", false) === false &&
      _.get(this, "props.isOptionSelected", false) === false
    ) {
      this.setState(
        {
          selectedOption: true
        },
        () => {
          this.props.slideClick(option);
          //_.set(this, "props.slideOption", []);
        }
      );
    }
  }
  render() {
    const slideOption = _.get(this, "props.slideOption", []);
    return (
      <ul id="12" className="list-group list-group-flush" key={guid()}>
        {slideOption.map((slideOption, index) => (
          <li
            id="l12"
            className={"s " + (this.state.disablebutton ? "disabled" : "")}
            disabled={
              _.get(this, "state.disablebutton", true) ? "disabled" : ""
            }
            key={guid()}
          >
            <a
              id="a12"
              href="javascript:void(0)"
              className={
                "list-group-item cardlinks pb-2 pt-2 " +
                (this.state.disablebutton ? "disabled" : "") +
                (_.get(this, "props.isOptionSelected", false) === true
                  ? "disabled"
                  : "") +
                (_.get(this, "props.isRTLFlow", false) &&
                _.get(this, "props.chat.font", "") !== "neutral"
                  ? " font-arabic-light "
                  : "")
              }
              onClick={() => this.selectedOption(slideOption)}
            >
              {slideOption.title}
            </a>
          </li>
        ))}
      </ul>
    );
  }
}

//Carousel wrapper component
class Carousel extends Component {
  constructor(props) {
    super(props);

    this.goToSlide = this.goToSlide.bind(this);
    this.goToPrevSlide = this.goToPrevSlide.bind(this);
    this.goToNextSlide = this.goToNextSlide.bind(this);

    this.state = {
      activeIndex: 0,
      isOptionSelected: false
    };
    this.CarouselSelect = this.CarouselSelect.bind(this);
  }

  goToSlide(index) {
    this.setState({
      activeIndex: index
    });
  }

  goToPrevSlide(e) {
    e.preventDefault();

    let index = this.state.activeIndex;
    let slidesLength = this.props.slides.length;

    if (index < 1) {
      index = slidesLength;
      //return false;
    }

    --index;

    this.setState({
      activeIndex: index
    });
  }

  componentDidMount() {
    console.log(this.props.carousel_id);
    //console.log(document.getElementById("a12").value);
    $("#l12").removeClass("disabled");
    $("#a12").removeClass("disabled"); 
    if (
      _.get(this, "props.slides", []).length > 1 &&
      ENVIORMENT.carousel_should_rotate === true
    ) {
      setInterval(
        e => this.goToNextSlide(true),
        ENVIORMENT.carousel_auto_rotate_duration
      );
    }
  }

  goToNextSlide(auto = false) {
    //e.preventDefault();

    let index = this.state.activeIndex;
    let slidesLength = this.props.slides.length;

    // if (auto === false &&index === slidesLength - 1) {
    //   return false;
    // }

    if (index === slidesLength - 1) {
      //if (index === slidesLength - 1) {
      index = -1;
    }
    ++index;
    if (index > slidesLength - 1) {
      index = slidesLength - 1;
    }
    this.setState({
      activeIndex: index
    });
  }

  CarouselSelect(selectedOption) {
    this.setState(
      {
        isOptionSelected: true,
        disablebutton: true
      },
      () => {
        this.props.slideSelect(selectedOption);
      }
    );
    //_.set(this, "props.slides", []);
  }

  render() {
    const slidisablebuttondes = _.get(this, "props.disablebutton", false);
    const slides = _.get(this, "props.slides", []);
    const activeIndex = _.get(this, "state.activeIndex", 0);
    let totalSlides = _.get(this, "props.slides", []).length;
    let newSlides = [];
    if (activeIndex === 0 && totalSlides > 1) {
      newSlides.push(slides[0]);
      newSlides.push(slides[1]);
    } else if (activeIndex === totalSlides - 1 && totalSlides > 1) {
      newSlides.push(slides[slides.length - 1]);
      newSlides.push(slides[0]);
    } else if (totalSlides > 1) {
      newSlides.push(slides[activeIndex]);
      newSlides.push(slides[activeIndex + 1]);
    }
    if (totalSlides === 1) {
      newSlides = slides;
    }

    return (
      <div className={slides.length > 0 ? "carousel mb-2" : ""}>
        {slides.length > 1 && (
          <CarouselLeftArrow
            onClick={e => this.goToPrevSlide(e)}
            active={this.state.activeIndex < 1 ? false : true}
          />
        )}
        <ul className="carousel__slides">
          {newSlides.map((slide, index) => (
            <CarouselSlide
              disablebutton={_.get(this, "props.disablebutton", false)}
              key={index}
              index={index}
              activeIndex={_.get(this, "state.activeIndex", 0)}
              slide={slide}
              onClick={e => this.goToSlide(index)}
              CarouselSelect={this.CarouselSelect}
              isOptionSelected={_.get(this, "state.isOptionSelected", false)}
              totalSlides={slides.length}
              isRTLFlow={_.get(this, "props.isRTLFlow", false)}
              lang={_.get(this, "props.lang", "English")}
              is_font_neutral={_.get(this, "props.is_font_neutral", false)}
            />
          ))}
        </ul>
        {slides.length > 1 && (
          <CarouselRightArrow
            onClick={e => this.goToNextSlide(e)}
            active={
              this.state.activeIndex === this.props.slides.length - 1
                ? false
                : true
            }
          />
        )}
        {false && (
          <ul className="carousel__indicators">
            {this.props.slides.map((slide, index) => (
              <CarouselIndicator
                disablebutton={_.get(this, "props.disablebutton", false)}
                key={index}
                index={index}
                activeIndex={this.state.activeIndex}
                isActive={this.state.activeIndex === index}
                onClick={e => this.goToSlide(index)}
              />
            ))}
          </ul>
        )}
      </div>
    );
  }
}

export default Carousel;
