import React, { Component } from "react";
import { isMobileSafari, isSafari } from "react-device-detect";
import Autocomplete from "react-google-autocomplete";
import { Translate } from "./../Language/Translate";
import { ENVIORMENT } from "./../../env";
//import { GoogleApiWrapper, Map } from "google-maps-react";

import _ from "lodash";
import "./map.scss";

export class GetUserLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userLocation: { lat: 32, lng: 32 },
      loading: true,
      isFormSubmitted: false,
      googleLocation: {}
    };
  }

  componentDidMount(props) {
    if (
      ENVIORMENT.IS_SSL_ENABLED &&
      (isMobileSafari === false && isSafari === false)
    ) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          this.setState(
            {
              userLocation: { lat: latitude, lng: longitude },
              loading: false
            },
            () => {
              this.props.userFormReply(
                JSON.stringify({
                  location: { lat: latitude, lng: longitude }
                })
              );
            }
          );
        },
        () => {
          //this.setState({ loading: false });
        }
      );
    }
  }

  setUserLocation() {
    if (_.isEmpty(this.state.googleLocation)) {
      return false;
    }
    this.setState(
      {
        isFormSubmitted: true,
        userLocation: {
          lat: this.state.googleLocation.geometry.location.lat(),
          lng: this.state.googleLocation.geometry.location.lng()
        }
      },
      () => {
        // this.props.userFormReply(
        //   JSON.stringify({
        //     location: {
        //       lat: this.state.userLocation.lat,
        //       lng: this.state.userLocation.lng
        //     }
        //   })
        // );
        this.props.userFormReply("/select_location{\"lat\":\""+this.state.userLocation.lat+"\",\"lng\":\""+this.state.userLocation.lng+"\"}");
      }
    );
  }

  setGoogleLocation(location) {
    this.setState(
      {
        googleLocation: location
      },
      () => {}
    );
  }

  render() {
    const { loading, userLocation } = this.state;
    
    if (
      ENVIORMENT.IS_SSL_ENABLED &&
      (isMobileSafari === false && isSafari === false)
    ) {
      if (loading) {
        return null;
      } 
      if (!loading) {
        
        return (
          <div className="bot map-container">
            <img
              src={
                "https://maps.googleapis.com/maps/api/staticmap?size=300x200&center=" +
                userLocation.lat +
                "," +
                userLocation.lng +
                "&markers=color:blue%7Clabel:your%2flocation%7Clocation%7C" +
                userLocation.lat +
                "," +
                userLocation.lng +
                "&" +
                "&zoom=17&key=AIzaSyCMquZleU6ORLHqKMPNAbXvZ9x8isZssxU"
              }
              className="pull-right"
              alt=""
            />
          </div>
        );
      }
    }
    if (!ENVIORMENT.IS_SSL_ENABLED || (isMobileSafari || isSafari)) {
      let opts = {};
      if (_.get(this, "state.isFormSubmitted", false)) {
        opts["disabled"] = "disabled";
      }
      return (
        <div className="google-place mb-2">
          <Autocomplete
            {...opts}
            style={{ width: "90%" }}
            onPlaceSelected={place => {
              this.setGoogleLocation(place);
            }}
            types={["establishment"]}
            componentRestrictions={{ country: "ae" }}
          />
          <button
            {...opts}
            type="button"
            className={
              "btn ml-3 mt-2 btn-block btn-text-white " +
              (_.isEmpty(this.state.googleLocation) ? "" : "btn-blue")
            }
            onClick={() => this.setUserLocation()}
          >
            {Translate("SUBMIT", _.get(this, "props.lang"))}
          </button>
        </div>
      );
    }
    return null;
  }
}
export default GetUserLocation;
// export default GoogleApiWrapper({
//   apiKey: "AIzaSyAENgyobPFGCNGorVG-GSOKWjK2GFOMF6A"
// })(GetUserLocation);
