import React, { Component } from "react";
import _ from "lodash";
import "./index.scss";
import { guid, decodeString, getTime, textToLink } from "./../utils/Utility";


export default class SessionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formSubmitted: false,
      fields: [],
      chatlogs: [],
		};
	}
  
  componentDidMount() {
    let fields = (this.props.data);
    this.setState({
      fields: (this.props.data)
    });

  }

  EndForm() {
  
    let End = 'End';
    
    this.setState({
    }, () => {
      this.props.userFormReply(
        JSON.stringify({
          End
        })
      )
    });    
  }
  RestartForm() {
    let Restart = 'Restart';
    
    this.setState({
    }, () => {
      this.props.userFormReply(
        JSON.stringify({
          Restart
        })
      )
    });    
  }
 

  render() {
    const fields = _.get(this, "state.fields", []);
    

    return (
     
      <div className="form-main">
       {/* <p>
            <span
              className={
                "float-left " +
                ((_.get(this, "props.isRTLFlow", false) === true && (_.get(this, "props.chat.font", "") !== "neutral"))
                  ? " font-arabic-light "
                  : "")
              }
              dangerouslySetInnerHTML={{
                __html: textToLink(
                  decodeString(_.get(this, "state.fields.text", ""))
                )
              }}
            />
          </p> */}
        <form className="pl-4 pr-4 pt-0">
					{
						_.map(fields, (field) => {
              
							// if (field === "end_session") {
							// 	return this.EndForm();
              // }
              // if (field === "restart_session") {
							// 	return this.RestartForm;
              // }
              // if (field.title === "Restart Session") {
							// 	return this.renderRestartButton(field)
              // }
						})
					}
				</form>
      </div>
    );
	}
	
}
