import React, { Component } from "react";
import _ from "lodash";
import "./Newautosuggest.scss";
import { guid } from "./../utils/Utility";
// images
import ChatScreen from "./../ChatScreen/ChatScreen";
import searchic from "./../../public/images/search_ic.svg";
import { createBrotliCompress } from "zlib";
import $ from "jquery";

class Newautosuggest extends Component {
  constructor(props) {
    console.log(props);
    super(props);
    this.state = {
      search: "",
      select: 0
    };
  }
  componentDidUpdate() {
    this.scrollToBottom();
  }
  scrollToBottom = () => {
    // console.log(this.messagesEnd);
    try {
      this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    } catch (Exception) {}
  };
  arabicchangeSearch(e) {
    this.scrollToBottom();

    console.log("inside");
    this.setState(
      {
        search: e.target.value
      },
      () => {
        setTimeout(() => {
          this.scrollToBottom();
        });
      }
    );
  }

  arabicselectOption(option) {
    this.setState({
      select: 1
    });

    this.props.formReply(option);
    _.set(this, "props.arabicdatasearch", []);
  }
  changeSearch(e) {
    this.scrollToBottom();

    console.log("inside");
    this.setState(
      {
        search: e.target.value
      },
      () => {
        setTimeout(() => {
          this.scrollToBottom();
        });
      }
    );
  }

  selectOption(option) {
    this.setState({
      select: 1
    });

    this.props.formReply(option);
    _.set(this, "props.data", []);
  }

  render() {
    console.log(this.props.isRTLFlow);
    if (this.props.isRTLFlow == true) {
      console.log("inside arabuc");
      const list = _.get(this, "props.arabicdatasearch", []);
      console.log(list);
      const se = _.get(this, "state.search", "");
      const regex = new RegExp(`(${se})`, "gi");
      if (list.length === 0) {
        return <div />;
      }
      return (
        <div>
          {this.state.select == 0 ? (
            <div
              className="specialization-main mb-2"
              ref={el => {
                this.messagesEnd = el;
              }}
            >
              <div className="search-fill">
                <input
                  className={
                    "form-control mr-sm-2 " +
                    (_.get(this, "props.isRTLFlow", false) === true
                      ? " font-arabic-light "
                      : "")
                  }
                  placeholder={_.get(this, "props.chat.text", "")}
                  onChange={e => this.arabicchangeSearch(e)}
                />
                <span className="search-ic">
                  <img src={searchic} alt="" />
                </span>
              </div>

              {se != "" ? (
                <div className="specialization-list">
                  <ul disabled={
                    _.get(this, "props.disablebutton", true)
                      ? "disabled"
                      : ""
                  }>
                    {list
                      .filter(option => {
                        return _.get(this, "state.search", "") === ""
                          ? true
                          : option.match(regex);
                      })
                      .map((option, index) => (
                        <li
                          className={
                            "s " +
                            (this.props.disablebutton ? "disabled" : "")
                          }
                          disabled={
                            _.get(this, "props.disablebutton", true)
                              ? "disabled"
                              : ""
                          }
                          key={guid()}
                          onClick={() => this.arabicselectOption(option)}
                        >
                          <span
                            className={
                              _.get(this, "props.isRTLFlow", false) === true
                                ? " font-arabic-light "
                                : ""
                            }
                          >
                            {option}
                          </span>
                        </li>
                      ))}
                  </ul>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            <div className="specialization-main mb-2 noneclaas">
              <div className="search-fill">
                <input
                  className={
                    "form-control mr-sm-2 " +
                    (_.get(this, "props.isRTLFlow", false) === true
                      ? " font-arabic-light "
                      : "")
                  }
                  placeholder={_.get(this, "props.chat.text", "")}
                  onChange={e => this.arabicchangeSearch(e)}
                />
                <span className="search-ic">
                  <img src={searchic} alt="" />
                </span>
              </div>

              <div className="specialization-list">
                {se != "" ? (
                    <ul disabled={
                      _.get(this, "props.disablebutton", true)
                        ? "disabled"
                        : ""
                    }>
                    {list
                      .filter(option => {
                        return _.get(this, "state.search", "") === ""
                          ? true
                          : option.match(regex);
                      })
                      .map((option, index) => (
                        <li
                          className={
                            "s " +
                            (this.props.disablebutton ? "disabled" : "")
                          }
                          disabled={
                            _.get(this, "props.disablebutton", true)
                              ? "disabled"
                              : ""
                          }
                          key={guid()}
                          onClick={() => this.arabicselectOption(option)}
                        >
                          <span
                            className={
                              _.get(this, "props.isRTLFlow", false) === true
                                ? " font-arabic-light "
                                : ""
                            }
                          >
                            {option}
                          </span>
                        </li>
                      ))}
                  </ul>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
        </div>
      );
    } else {
      const list = _.get(this, "props.data", []);
      const se = _.get(this, "state.search", "");
      const regex = new RegExp(`(${se})`, "gi");
      if (list.length === 0) {
        return <div />;
      }
      return (
        <div>
          {this.state.select == 0 ? (
            <div
              className="specialization-main mb-2"
              ref={el => {
                this.messagesEnd = el;
              }}
            >
              <div className="search-fill">
                <input
                  className={
                    "form-control mr-sm-2 " +
                    (_.get(this, "props.isRTLFlow", false) === true
                      ? " font-arabic-light "
                      : "")
                  }
                  placeholder={_.get(this, "props.chat.text", "")}
                  onChange={e => this.changeSearch(e)}
                />
                <span className="search-ic">
                  <img src={searchic} alt="" />
                </span>
              </div>

              {se != "" ? (
                <div className="specialization-list">
                  <ul
                    disabled={
                      _.get(this, "props.disablebutton", true) ? "disabled" : ""
                    }
                  >
                    {list
                      .filter(option => {
                        return _.get(this, "state.search", "") === ""
                          ? true
                          : option.match(regex);
                      })
                      .map((option, index) => (
                        <li
                         className={
                            "s " +
                            (this.props.disablebutton ? "disabled" : "")
                          }
                          disabled={
                            _.get(this, "props.disablebutton", true)
                              ? "disabled"
                              : ""
                          }
                          key={guid()}
                          onClick={() => this.selectOption(option)}
                        >
                          <span
                            className={
                              _.get(this, "props.isRTLFlow", false) === true
                                ? " font-arabic-light "
                                : ""
                            }
                          >
                            {option}
                          </span>
                        </li>
                      ))}
                  </ul>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            <div className="specialization-main mb-2 noneclaas">
              <div className="search-fill">
                <input
                  className={
                    "form-control mr-sm-2 " +
                    (_.get(this, "props.isRTLFlow", false) === true
                      ? " font-arabic-light "
                      : "")
                  }
                  placeholder={_.get(this, "props.chat.text", "")}
                  onChange={e => this.changeSearch(e)}
                />
                <span className="search-ic">
                  <img src={searchic} alt="" />
                </span>
              </div>

              <div className="specialization-list">
                {se != "" ? (
                  <ul
                    disabled={
                      _.get(this, "props.disablebutton", true) ? "disabled" : ""
                    }
                  >
                    {list
                      .filter(option => {
                        return _.get(this, "state.search", "") === ""
                          ? true
                          : option.match(regex);
                      })
                      .map((option, index) => (
                        <li
                          className={
                            "s " +
                            (this.props.disablebutton ? "disabled" : "")
                          }
                          disabled={
                            _.get(this, "props.disablebutton", true)
                              ? "disabled"
                              : ""
                          }
                          key={guid()}
                          onClick={() => this.selectOption(option)}
                        >
                          <span
                            className={
                              _.get(this, "props.isRTLFlow", false) === true
                                ? " font-arabic-light "
                                : ""
                            }
                          >
                            {option}
                          </span>
                        </li>
                      ))}
                  </ul>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}
        </div>
      );
    }
  }
}
export default Newautosuggest;
