import React from "react";
import _ from "lodash";
import "./slider.scss";

import Carousel from "./Carousel";
class Slider extends React.Component {
  formSubmitted = false;
  constructor(props) {
    super(props);
    this.state = {};
    this.slideSelect = this.slideSelect.bind(this);
  }
  componentDidMount() {}
  componentDidUpdate() {}
  slideSelect(selectedSlideOption) {
    this.props.multiLangQuickReply(selectedSlideOption);
  }
  render() {
    if (this.props.txtmsg != "") {
      this.formSubmitted = true;
    }
    return (
      <div>
        <Carousel
          slides={_.get(this, "props.sliderData.data", [])}
          carousel_id={_.get(this, "props.sliderData.carousel_id", "")}
          slideSelect={this.slideSelect}
          isRTLFlow={_.get(this, "props.isRTLFlow", false)}
          disablebutton={_.get(this, "props.disablebutton", false)}
          lang={_.get(this, "props.lang", "English")}
          is_font_neutral={
            _.get(this, "props.sliderData.font", "") === "neutral"
          }
        />
        {/* {!this.formSubmitted ? (
          <Carousel
            slides={_.get(this, "props.sliderData.data", [])}
            slideSelect={this.slideSelect}
            isRTLFlow={_.get(this, "props.isRTLFlow", false)}
            lang={_.get(this, "props.lang", "English")}
            is_font_neutral={
              _.get(this, "props.sliderData.font", "") === "neutral"
            }
          />
        ) : (
          ""
        )} */}
      </div>
    );
  }
}

export default Slider;
