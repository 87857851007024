import React from "react";
import _ from "lodash";
import "./sliderb.scss";

import Carousel from "./Carousel";
class Sliderb extends React.Component {
  formSubmitted = false;
  submitdata = {};
  constructor(props) {
    super(props);
    this.state = {};
    this.slideSelect = this.slideSelect.bind(this);
  }
  componentDidMount() {}
  componentDidUpdate() {}
  slideSelect(selectedSlideOption) {
    this.props.multiLangQuickReply(selectedSlideOption);
  }
  render() {
    if (this.props.txtmsg != "") {
      this.formSubmitted = true;
    }
    this.submitdata = _.get(this, "props.sliderData.Submitdata", {});

    return (
      <div>
         <Carousel
            slides={_.get(this, "props.sliderData.data", [])}
            slideSelect={this.slideSelect}
            isRTLFlow={_.get(this, "props.isRTLFlow", false)}
            lang={_.get(this, "props.lang", "English")}
            is_font_neutral={
              _.get(this, "props.sliderData.font", "") === "neutral"
            }
          />
          <div className="">
				<div className="col-6 widthset">
					<button
                        type="button"
                        
						className={
							"btn btn-block btn-text-white " +
							("btn-blue") +
							(_.get(this, "props.isRTLFlow", false) === true
								? "font-arabic"
								: "")
            }					 
          
                    >
                    <a target="_blank"
                        href={ _.get(this.submitdata, "link", "") }> { _.get(this.submitdata, "title", "") }</a>
                    
					</button>
				</div>
			</div>
      </div>
    );
  }
}

export default Sliderb;
