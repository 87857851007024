import React from "react";
import _ from "lodash";
import {ENVIORMENT} from './../../env';
import PoweredBy from "./../PoweredBy/PoweredBy";
import wave from "./../../public/images/wave.png";
import botAvatarmale from "./../../public/images/bot-avatar-Leo.png";
import botAvatarfemale from "./../../public/images/bot-avatar-Mira.png";

import mira from "./../../public/images/mira.png";
import leo from "./../../public/images/leo.png";
//images 
import collapsclose from "./../../public/images/collapsclose.svg";

class LayoutTwo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAvatar: "",
    };
  }
  
  componentDidMount() {}
  componentDidUpdate() {}

  selectAvatar(avatar) {    
    this.setState({
      selectedAvatar: avatar
    });
  }

  startConversation( lang ) {
      let dispName = "";
      if( lang === "English") {
        this.setState({
          selectedAvatar: "Leo"
        }, () => {
          dispName = "Leo";
          this.props.startConversation(
            _.get(this, "state.selectedAvatar", ""),
            dispName,
            lang
          );
        });

        // dispName = ( _.get(this, "state.selectedAvatar", "") === "Leo" ? "Leo" : "Mira");
      } else {
        this.setState({
          selectedAvatar: "Mira"
        }, () => {
          dispName = "ميرا";
          this.props.startConversation(
            _.get(this, "state.selectedAvatar", ""),
            dispName,
            lang
          );
        });
      }

    }    
  endConversation() {
    this.props.endConversation();
  }
  render() {
    return (
      <div className={"start-converstion"}>
        <div className="container">
          <div className="row">
            <div className="col position-relative avatar-container">
              <div className="row">
                <div className="cover-pattern">
                  <span className="box-header-ic">
                    <span
                      className="btn-link d-none"
                      onClick={() => this.endConversation()}
                    >
                      <img src={collapsclose} alt="" />
                    </span>
                  </span>

                  <div className="pt-4 pb-4">
                    <p>
                      <h3>
                        Marhaba! <img src={wave} alt="" className="text_ic" />{" "}
                        <span className="font-arabic">مرحبا </span>
                      </h3>
                      <span className="font-arabic font-arabic-20">
                        اختر ميرا أو ليو لنبدأ المحادثة
                      </span>
                      <br />
                      Choose Leo or Mira to proceed
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="screen-buttons-main px-4 py-4 style">
              <span onClick={() => this.startConversation("Arabic")}>
                <img src={mira} alt="" />
              </span>

              <span onClick={() => this.startConversation("English")}>
                <img src={leo} alt="" />
              </span>
            </div>

            <div className="text-container">
              <div className="white-pattern">
                <div className="body-text pt-5 px-3">
                  <p>
                    <b>998 </b>
                    <span className="font-arabic font-arabic-16">
                      أوافق على الشروط والأحكام من ميدكير{" "}
                    </span>
                    <br />
                    Please call 998 for a Medical Emergency.
                  </p>
                </div>

                {/* <div className="col body-text pt-2 pb-0">
                <button
                  className={"btn col-6 btn-text-white" + ( (_.get(this, "state.selectedAvatar", "") !== "") ? " btn-start": "")}
                  onClick={() => this.startConversation("English")}
                >
                  Let’s Talk
                </button>

                <button
                  className={"btn col-6 btn-text-white font-arabic" + ( (_.get(this, "state.selectedAvatar", "") !== "") ? " btn-start": "")}
                  onClick={() => this.startConversation("Arabic")}
                >
                  دعنا نتحدث
                </button>

              </div> */}

                <div className="term-text">
                  <p className="font-arabic-light">
                    <b>998</b>في حالات الطوارئ الرجاء الاتصال على{" "}
                    <a
                      href="https://www.medcare.ae/ar/contents/view/terms-and-conditions.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      الشروط والأحكام
                    </a>
                  </p>
                  <p className="p-text">
                    By starting this chat, I agree to the Medcare{" "}
                    <a
                      href={ENVIORMENT.policy_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms and Conditions
                    </a>
                  </p>
                </div>
              </div>
              <PoweredBy className="powerd-by-container" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LayoutTwo;
