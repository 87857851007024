import React from "react";
import './powered-by.scss';
class PoweredBy extends React.Component {
  render() {
    return (
      <div className="powered-by">
        <p className="pl-2 pr-2">
          Powered by <a target="_blank" rel="noopener noreferrer" href="http://thatsbluelogic.com/BL-Chatbot-LandingPage.php"><span className="text-light-blue-bold">&lt;blue</span><span className="text-dark-blue-bold">/ogic</span>&gt; </a>{" "}
        </p>
      </div>
    );
  }
}

export default PoweredBy;
