import React from "react";
import _ from "lodash";
import { decodeString, getTime } from "./../utils/Utility";
import "./UserReply.scss";

class UserReply extends React.Component {
  constructor(props) {
		super(props);
		this.state = {
      time: getTime()
    }
  }
  render() {
    const time = this.state.time;
    return (
      <div className="bitfixwidth">
      <div className="user-msg">
        <div className="chat-time">
            {time}
        </div>
        <p className={((_.get(this, "props.isRTLFlow", false) === true ) ? (" font-arabic-light ") : (""))}>
          {decodeString(_.get(this, "props.chat.text", ""))}
        </p>
      </div>
      </div>
    );
  }
}

export default UserReply;
