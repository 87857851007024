import React from 'react';
import Chatbot from './components/Chatbot/Chatbot';

import './main.scss';

class App extends React.Component {
  render() {
    return (
      <div className="app">
        <Chatbot></Chatbot>        
      </div>
    );
  }
}

export default App;
