export const ENVIORMENT = {
  socketUrl: "wss://medcarehospital.bluelogic.ai/ws",
  download_url: "http://18.216.148.216:3001/download",
  channel: "socket",
  // theme: "al-ain",
  // project: "al-ain",
  // policy_url : "https://am.abudhabi.ae/mServices/home/TermsAndConditions",
  theme: "medcare",
  project: "medcare",
  policy_url:
    "https://www.medcare.ae/en/contents/view/terms-and-conditions.html",
  rtl_languages: ["Arabic"],
  carousel_auto_rotate_duration: 5000,
  carousel_should_rotate: false,
  IS_SSL_ENABLED: false,
};
