import {lang_en} from "./en";
import {lang_ar} from "./ar";
import _ from "lodash";

export const Translate = (keycode, lang_code = "English") => {
    
    if (lang_code === "English") {
        return _.get(lang_en, keycode, "nu");
    }

    if (lang_code === "Arabic") {
        return _.get(lang_ar, keycode, "nu");
    }

    if (lang_code === "avatar") {
        if (keycode === "Leo")  {
            return "Leo is typing";
        }

        if (keycode === "ليو")  {
            return "ليو يكتب";
        }

        if (keycode === "Mira")  {
            return "Mira is typing";
        }

        if (keycode === "ميرا")  {
            return "ميرا تكتب";
        }

    }
    return "";
}