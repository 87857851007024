export const lang_ar = {
    "SUBMIT" : "موافق",
    "MAIN" : "القائمة",
    "MAIN_MENU" : "القائمة الرئيسية",
    "RESTART_SESSION" : "إعادة الجلسة",
    "END": "إنهاء الجلسة",
    "TYPE_A_MESSAGE": "",
    "CONFIRM": "موافق",
    "ENTER_NAME": "الإسم",
    "DATE_OF_BIRTH": "تاريخ الميلاد",
    "DATE": "يرجى إدخال التاريخ",
    "MOBILE_NUMBER": "رقم الهاتف",
    "EMAIL_ID": "البريد الالكتروني",
    "DD": "اليوم",
    "MM": "الشهر",
    "YYYY": "السنة",
    "IS_TYPING":"يكتب",
    "RESEND": "إعادة إرسال الرمز",
    "SELECT": "اختر",
    "AHMED_TYPING" : "أحمد يكتب",
    "SARA_TYPING": "سارة تكتب",
    "LIVE_AGENT": "المحادثة الحية"
    // "ENTER_PAST_DATE": "يرجى إدخال التاريخ الماضي",
    // "ENTER_FUTURE_DATE": "يرجى إدخال تاريخ المستقبل",
};