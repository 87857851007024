import React, { Component } from "react";
import _ from "lodash";
import "./list.scss";
import { guid } from "./../utils/Utility";
import { ENVIORMENT } from "./../../env";
import { Translate } from "./../Language/Translate";

class List extends Component {
  project;
  constructor(props) {
    super(props);
    this.state = {
      isSelected: false,
      selectedIndex: -1
    };
    this.project = ENVIORMENT.project;
  }

  selectOption(option, index) {
    this.setState({
      isSelected: true,
      selectedIndex: index
    }, () => {
      this.props.multiLangQuickReply(option.buttons[0]);
    });
  }

  generateListSection(list, index) {
    let opts = {};
    if (_.get(this, "state.isSelected", false)) {
      opts["disabled"] = "disabled";
    }
    return (
      <li key={guid()}>
        <div className="col-8 doctor-list-detail float-left">
          <span className={((_.get(this, "props.isRTLFlow", false) === true ) ? (" font-arabic-light ") : (""))}>{_.get(list, "label", "")}</span>
          <p className={((_.get(this, "props.isRTLFlow", false) === true ) ? (" font-arabic-light ") : (""))}>
            {_.get(list, "description", "")}
          </p>
          <button
            disabled={
              _.get(this, "props.disablebutton", true)
                ? "disabled"
                : ""
            }
            className={
              "btn btn-sm list-section-btn "+
                (_.get(this, "state.selectedIndex", -1) === index ? "active" : 
                  ((_.get(this, "state.isSelected", false) === true ? "disabled" : "")
                )
              )
              +((_.get(this, "props.isRTLFlow", false) === true ) ? (" font-arabic-light ") : (""))
            }
            {...opts}
            onClick={() => this.selectOption(list, index)}
          >
            { Translate("SELECT", _.get(this, "props.lang")) }
          </button>
        </div>
        <div className="col-4 text-right float-right">
          <img
            src={
              _.get(list, "image_type", "") === "name"
                ? require(`./../../public/images/list/${this.project}/${_.get(
                    list,
                    "image",
                    ""
                  )}`)
                : _.get(list, "image", "")
            }
            alt=""
            className="img-fluid rounded"
          />
        </div>
      </li>
    );
  }

  render() {
    const chat = _.get(this, "props.chat");
    return (
      <div className="list-section-main">
        <div className="list-section-list">
          <ul>
            {chat.data.map((data, index) => {
              return this.generateListSection(data, index);
            })}
          </ul>
        </div>
      </div>
    );
  }
}

export default List;
