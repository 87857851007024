import React, { Component } from "react";
import _ from "lodash";
import { ENVIORMENT } from "./../../env";
import { Translate } from "./../Language/Translate";
import tickIcon from "./../../public/images/tick.svg";
class Temp extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isEmailValid: false,
      emailValue: "",
      emailField: "",    
      value1: "",
      value2: "",
      listobject: [],
      Values: {},
    };
    
  }
  isValidOrInvalid(stateValid, stateValue) {
    if (_.get(this.state, stateValue, "") !== "") {
      return !_.get(this.state, stateValid, false) ? "is-invalid" : "is-valid";
    }
    return "";
  }
  handleClick(event) {
    let value1 = this.state.value1;
    let value2 = this.state.value2;
    let obj = {
      value1 : value1,
      value2 : value2,
    };
  }

  handleInputValue = (evt, field) => {
    const fieldVal = evt.target.value;
     switch (field) {
      case "value1": {
        if (
          !fieldVal.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        ) {
          this.setState(
            {
              isEmailValid: false
            },
          );
        } else {
          this.setState(
            {
              isEmailValid: true
            },
          );
        }
        this.setState(
          {
            value1: fieldVal
          },
        );
        break;
      }

      // case "value1": {
      //   this.setState(
      //     {
      //       value1: fieldVal
      //     },
      //   );
      //   break;
      // }

      case "value2": {
        this.setState(
          {
            value2: fieldVal
          },
        );
        break;
      }
       default: {
      }
    }
  };
  render() {

    return <div>
    <div className="input-group mb-2">
      <input
        type="text"
        className={
          "rounded form-control email-field " +
          this.isValidOrInvalid("isEmailValid", "emailValue")
        }
        placeholder={
          "Value 1"
        }
        onChange={e => this.handleInputValue(e, "value1")}
      />
      
    </div>
    <div className="input-group mb-3">
      <input
          disabled={
            _.get(this, "props.disablebutton", true)
              ? "disabled"
              : ""
          }
        type="text"
        className="form-control"
        placeholder={"Value 2"}
        onChange={e => this.handleInputValue(e, "value2")}
      />
    </div>
    <div>
        <button disabled={
          _.get(this, "props.disablebutton", true)
            ? "disabled"
            : ""
        }
        value={this.state.value1} className="input-group mb-3 form-control" type="sumbit" onClick={ (e) => this.handleClick(e) }>Sumbit</button>
    </div>
  </div>
  }
}
export default Temp;
