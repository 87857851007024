import React, { Component } from "react";
import _ from "lodash";
import "./auto-suggest.scss";
import { guid } from "./../utils/Utility";
// images
import searchic from "./../../public/images/search_ic.svg";

class Autosuggest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: ""
    };
  }

  changeSearch(e) {
    this.setState({
      search: e.target.value
    })
  }

  selectOption(option) {
    this.props.formReply(option);
    _.set(this, "props.chat.data", []);
  }

  render() {
    const list = _.get(this, "props.chat.data", []);
    const se = _.get(this, "state.search", "");
    const regex = new RegExp(`(${se})`, 'gi');
    if (list.length === 0) {
      return <div />;
    }
    return <div className="specialization-main mb-2">
      <div className="search-fill">
        <input className={"form-control mr-sm-2 " +
          (_.get(this, "props.isRTLFlow", false) === true ? " font-arabic-light " : "")
        }
          placeholder={_.get(this, "props.chat.text", "")}
          onChange={(e) => this.changeSearch(e)} />
        <span className="search-ic"><img src={searchic} alt="" /></span>
      </div>
      <div className="specialization-list">
        <ul>
          {
            list
              .filter((option) => {
                return _.get(this, "state.search", "") === "" ? true : (option).match(regex);
              })
              .map((option, index) => (
                <li
                  className={
                    "s " +
                    (this.props.disablebutton ? "disabled" : "")
                  }
                   disabled={
                  _.get(this, "props.disablebutton", true)
                    ? "disabled"
                    : ""
                } key={guid()} onClick={() => this.selectOption(option)}>
                  <span className={((_.get(this, "props.isRTLFlow", false) === true) ? (" font-arabic-light ") : (""))}>{option}</span>
                </li>
              ))
          }
        </ul>
      </div>
    </div>;
  }
}
export default Autosuggest;
