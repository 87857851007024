import React from "react";
import _ from "lodash";
import InitialScreen from "./../InitalScreen/InitialScreen";
import ChatScreen from "./../ChatScreen/ChatScreen";
import { ENVIORMENT } from "./../../env";

// import Draggable from 'react-draggable';
// Style sheet
import "./chatbot.scss";
import axios from "axios";
class Chatbot extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_bot_visible: false,
      is_conversation_start: false,
      selectedAvatar: "",
      avatarDispName: "",
      isRTLFlow: false,
      language: "English",
      moveMe: {},
      face: {},
      message: {},
      datasearch: [],
      arabicdatasearch: []
    };

    this.loadConversationScreen = this.loadConversationScreen.bind(this);
    this.endConversation = this.endConversation.bind(this);
    this.toggleRTL = this.toggleRTL.bind(this);
    this.toggleBotButton = this.toggleBotButton.bind(this);
  }

  /*
    Toggle display of chat screen
  */
  toggleBotButton() {
    this.setState({ is_bot_visible: !this.state.is_bot_visible });
  }

  endConversation() {
    this.setState({
      is_bot_visible: true,
      is_conversation_start: false,
      isRTLFlow: false,
      language: "English"
    });
  }

  toggleRTL(lang) {
    if (_.indexOf(ENVIORMENT.rtl_languages, lang) >= 0) {
      this.setState({
        isRTLFlow: true,
        language: lang
      });
    }
  }

  /*
    load conversation screen on click of start conversation button
  */
  loadConversationScreen(avatar, avatarDispName) {
    this.setState({
      is_conversation_start: !this.state.is_conversation_start,
      avatarDispName: avatarDispName,
      selectedAvatar: avatar
    });
  }

  
  componentDidMount() {
    let data = '';

    this.lastRequestId = axios
      .post("https://medcare.ae/medcare_api/REST/doctor/fetchList", data)
      .then(res => {
        {
          // console.log(res);
          _.map(res.data.data, title => {
if(title.language =="en"){
  this.setState({
              datasearch: [...this.state.datasearch, title.title]
            });
}
if(title.language =="ar"){
  this.setState({
	arabicdatasearch: [...this.state.arabicdatasearch, title.title]
            });
}
           
          });
        }
        // console.log(this.state.arabicdatasearch);
      });
    // const init_chat = JSON.parse(sessionStorage.getItem("init_chat"));
    
    // if (init_chat != null) {
    //   this.loadConversationScreen(init_chat.avatar, init_chat.avatarDispName);
    //   this.toggleRTL(init_chat.lang);
    // }
    //setTimeout(() => {
      if(!(_.get(this, "state.is_bot_visible", false))) {
        this.toggleBotButton();
      }      
    //}, 7000);
    
    this.setState(
      {
        moveMe: document.getElementById("movable"),
        face: document.getElementById("face"),
        message: document.getElementById("message")
      },
      () => {}
    );
  }
  componentDidUpdate() {
    
  }
  render() {
    return (
      <div
        className={
          _.get(this, "state.isRTLFlow", false) === true ? "lang-rtl" : ""
        }
      >
        <div className={this.state.is_bot_visible ? "" : "hide"}>
          {/* <Draggable axis="x" bounds="body"> */}
          <div className={"main-chat-box"}>
            {!this.state.is_conversation_start && (
              <InitialScreen
                loadConversationScreen={this.loadConversationScreen}
                toggleRTL={this.toggleRTL}
                endConversation={this.endConversation}
              />
            )}
            {this.state.is_conversation_start && (
              <ChatScreen
                endConversation={this.endConversation}
                avatar={_.get(this, "state.selectedAvatar", "")}
                search={_.get(this, "state.datasearch", [])}
                arabicdatasearch={_.get(this, "state.arabicdatasearch", [])}
                isConversationOpen={_.get(this, "state.is_bot_visible", false)}
                toggleRTL={this.toggleRTL}
                isRTLFlow={_.get(this, "state.isRTLFlow", false)}
                avatarDispName={_.get(this, "state.avatarDispName", "")}
                lang={_.get(this, "state.language", "English")}
                toggleBotButton={this.toggleBotButton}
              />
            )}
          </div>
          {/* </Draggable>         */}
        </div>
        {/*
        <div
          className={
            "bottom-toggle " + (this.state.is_bot_visible ? "hide" : "")
          }
        >
          <div
            className={
              "icon_ic chat_ic " + (this.state.is_bot_visible ? "hide" : "")
            }
            onClick={() => this.toggleBotButton()}
          />
          <div
            className={
              "icon_ic close_ic " + (this.state.is_bot_visible ? "" : "hide")
            }
            onClick={() => this.toggleBotButton()}
          />
        </div>
            */}
      </div>
    );
  }
}
export default Chatbot;
