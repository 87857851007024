import React from "react";
import _ from "lodash";
import { ENVIORMENT } from "./../../env";
import { dateToDBFormat, dateToDispFormat } from "./../utils/Utility";
import { Translate } from "./../Language/Translate";
import "./fotm-generate.scss";
import tickIcon from "./../../public/images/tick.svg";
import arrowIcon from "./../../public/images/arrow.svg";
import slashIcon from "./../../public/images/date-slash.png";
/*
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import {formatDate,parseDate,} from 'react-day-picker/moment';
*/
import Calendar from "rc-calendar";
import DatePicker from "rc-calendar/lib/Picker";
import moment from "moment";
//import {formatDate,parseDate,} from 'react-day-picker/moment';
//import PropTypes from 'prop-types';
const format = "DD-MM-YYYY";

const now = moment();

now.locale("en-gb").utcOffset(0);

function getFormat(time) {
  return time ? format : "DD-MM-YYYY";
}

const defaultCalendarValue = now.clone();
defaultCalendarValue.add(-1, "month");

function disabledDate(current) {
  if (!current) {
    // allow empty select
    return false;
  }
  const date = moment();
  date.hour(0);
  date.minute(0);
  date.second(0);
  return current.valueOf() > date.valueOf(); // can not select days before today
}
const multiFormats = ["DD/MM/YYYY"];

class FormGenerate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dateValue: new Date(),
      to: undefined,
      from: undefined,
      isDisabled: false,
      dateDayValue: "",
      dateMonthValue: "",
      dateYearValue: "",
      nameValue: "",
      phoneCodeValue: "971",
      phoneNumberValue: "",
      emailValue: "",
      emiratesIdNumber: "",
      tenancyConactNumber: "",
      isDateValid: false,
      isDateDayValid: false,
      isDateMonthValid: false,
      isDateYearValid: false,
      isNameValid: false,
      isPhoneCodeValid: false,
      isPhoneNumberValid: false,
      isEmailValid: false,
      isValidEmiratesIdNumber: "",
      isValidTenancyConactNumber: "",
      stepsCompleted: 0,
      otpFieldOne: "",
      otpFieldTwo: "",
      otpFieldThree: "",
      formSubmitted: false,
      emailField: ""
    };
    this.handleDayClick = this.handleDayClick.bind(this);
  }
  componentDidMount() {
    if (document.getElementsByClassName("rc-calendar-input").length > 0) {
      const datePicker = document.getElementsByClassName(
        "rc-calendar-input"
      )[0];
      datePicker.childNodes[0].setAttribute("readOnly", true);
    }
  }
  componentDidUpdate() { }
  onChange = value => {
    if (value === null) {
      this.setState(
        {
          value
        },
        () => {
          this.handleDayClick(undefined);
        }
      );
    } else {
      this.setState(
        {
          value
        },
        () => {
          this.handleDayClick(value);
        }
      );
    }
  };

  onStandaloneSelect(value) {
  }

  onStandaloneChange(value) {
  }
  disabledDate(current) {
    if (!current) {
      // allow empty select
      return false;
    }
    const date = moment();
    date.hour(0);
    date.minute(0);
    date.second(0);
    if (_.get(this, "props.chat.range", "") === "future") {
      return current.valueOf() < date.valueOf(); // can select future days
    } else {
      return current.valueOf() > date.valueOf(); // can select past days
    }
  }
  onShowTimeChange = e => {
    this.setState({
      showTime: e.target.checked
    });
  };

  onShowDateInputChange = e => {
    this.setState({
      showDateInput: e.target.checked
    });
  };

  toggleDisabled = () => {
    this.setState({
      disabled: !this.state.disabled
    });
  };
  isValidDate() {
    let day = parseInt(_.get(this, "state.dateDayValue", null));
    let month = parseInt(_.get(this, "state.dateMonthValue", -1));
    let year = parseInt(_.get(this, "state.dateYearValue", null));
    month = month - 1;
    const d = new Date(year, month, day);
    const today = new Date();

    if (
      d.getFullYear() >= 1900 &&
      d.getFullYear() <= today.getFullYear() + 100 &&
      (d.getFullYear() === year &&
        d.getMonth() === month &&
        d.getDate() === day)
    ) {
      if (
        _.get(this, "props.chat.type", "") === "new_user_form" ||
        _.get(this, "props.chat.type", "") === "existing_user_form" ||
        _.get(this, "props.chat.range", "") === "past"
      ) {
        const now = new Date();
        now.setHours(0, 0, 0, 0);
        if (d >= now) {
          return false;
        }
      }

      if (_.get(this, "props.chat.range", "") === "future") {
        const now = new Date();
        now.setHours(0, 0, 0, 0);
        if (now >= d) {
          return false;
        }
      }

      return true;
    }

    //return false;
  }

  checkDate() {
    if (!this.isValidDate()) {
      this.setState({
        isDateDayValid: false,
        isDateMonthValid: false,
        isDateYearValid: false
      });
    } else {
      this.setState({
        isDateDayValid: true,
        isDateMonthValid: true,
        isDateYearValid: true
      });
    }
  }

  handleInputValue = (evt, field) => {
    const fieldVal = evt.target.value;

    switch (field) {
      case "day": {
        const re = /^[0-9\b]+$/;
        if (!re.test(fieldVal) && fieldVal !== "") {
          break;
        }
        this.setState(
          {
            dateDayValue: fieldVal
          },
          () => {
            this.checkDate();
            this.generateStepsCount();
            if (fieldVal.length === 2) {
              if (
                _.get(this, "props.chat.type", "") === "new_user_form" ||
                _.get(this, "props.chat.type", "") === "existing_user_form" ||
                _.get(this, "props.chat.type", "") === "date"
              ) {
                this.dateMonthValue.focus();
              }
            }
          }
        );
        break;
      }
      case "month": {
        const re = /^[0-9\b]+$/;
        if (!re.test(fieldVal) && fieldVal !== "") {
          break;
        }
        this.setState(
          {
            dateMonthValue: fieldVal
          },
          () => {
            this.checkDate();
            this.generateStepsCount();
            if (fieldVal.length === 2) {
              this.dateYearValue.focus();
            }
          }
        );
        break;
      }
      case "year": {
        const re = /^[0-9\b]+$/;
        if (!re.test(fieldVal) && fieldVal !== "") {
          break;
        }
        this.setState(
          {
            dateYearValue: fieldVal
          },
          () => {
            this.checkDate();
            this.generateStepsCount();
            if (fieldVal.length === 4) {
              if (
                _.get(this, "props.chat.type", "") === "new_user_form" ||
                _.get(this, "props.chat.type", "") === "existing_user_form"
              ) {
                if (_.get(this, "props.isRTLFlow", false) === true) {
                  if (
                    _.get(this, "state.dateDayValue", "").length > 0 &&
                    _.get(this, "state.dateMonthValue", "").length > 0
                  ) {
                    this.phoneNumberValue.focus();
                  }
                } else {
                  if (
                    _.get(this, "state.dateDayValue", "").length > 0 &&
                    _.get(this, "state.dateMonthValue", "").length > 0
                  ) {
                    this.phoneNumberValue.focus();
                  }
                }
              }

              if (_.get(this, "props.chat.type", "") === "date") {
                if (_.get(this, "props.isRTLFlow", false) === true) {
                  this.dateMonthValue.focus();
                } else {
                  if (
                    _.get(this, "state.dateDayValue", "").length > 0 &&
                    _.get(this, "state.dateMonthValue", "").length > 0
                  ) {
                    this.submitButton.focus();
                  }
                }
              }
            }
          }
        );
        break;
      }
      case "name": {
        if (fieldVal.length === 0) {
          this.setState({
            isNameValid: false,
            nameValue: ""
          }, () => {
            this.generateStepsCount();
          });
        } else {
          if (fieldVal.match(/^[a-zA-Z- ]*$/)) {
            this.setState({
              isNameValid: true,
              nameValue: fieldVal
            }, () => {
              this.generateStepsCount();
            });
          }
        }
        break;
      }
      case "email": {
        if (
          !fieldVal.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        ) {
          this.setState(
            {
              isEmailValid: false
            },
            () => {
              this.generateStepsCount();
            }
          );
        } else {
          this.setState(
            {
              isEmailValid: true
            },
            () => {
              this.generateStepsCount();
            }
          );
        }
        this.setState(
          {
            emailValue: fieldVal
          },
          () => {
            this.generateStepsCount();
          }
        );
        break;
      }
      case "phonecode": {
        if (!(fieldVal >= 1 && fieldVal <= 9999)) {
          this.setState({
            isPhoneCodeValid: false,
            phoneCodeValue: ""
          });
        } else {
          this.setState({
            isPhoneCodeValid: true,
            phoneCodeValue: fieldVal
          });
        }
        break;
      }
      case "phonenumber": {
        const re = /^[0-9\b]+$/;
        if (!re.test(fieldVal) && fieldVal !== "") {
          break;
        }
        if (

          !("971" + fieldVal).match(
            /^(?:\+971|00971|0|971)(?!2)((?:2|3|4|5|6|7|9|50|51|52|55|56)[0-9]{8,})/m
          )
        ) {

          this.setState(
            {
              isPhoneNumberValid: false,
              phoneNumberValue: fieldVal
            },
            () => {
              this.generateStepsCount();
            }
          );
        } else {
          this.setState(
            {
              isPhoneNumberValid: true,
              phoneNumberValue: fieldVal
            },
            () => {
              this.generateStepsCount();

              if (fieldVal.length === 9) {
                if (_.get(this, "props.chat.type", "") === "new_user_form") {
                  this.emailField.focus();
                }
                if (
                  _.get(this, "props.chat.type", "") === "existing_user_form"
                ) {
                  this.submitButton.focus();
                }
              }
              //
            }
          );
        }
        break;
      }
      case "otpFieldOne": {
        const re = /^[0-9\b]+$/;
        if (re.test(fieldVal) || fieldVal === "") {
          this.setState(
            {
              otpFieldOne: fieldVal
            },
            () => {
              if (_.get(this, "state.otpFieldOne", "") !== "") {
                this.otpFieldTwo.focus();
              }
            }
          );
        }
        break;
      }
      case "otpFieldTwo": {
        const re = /^[0-9\b]+$/;
        if (re.test(fieldVal) || fieldVal === "") {
          this.setState(
            {
              otpFieldTwo: fieldVal
            },
            () => {
              if (_.get(this, "state.otpFieldTwo", "") !== "") {
                this.otpFieldThree.focus();
              } else {
                this.otpFieldOne.focus();
              }
            }
          );
        }
        break;
      }
      case "otpFieldThree": {
        const re = /^[0-9\b]+$/;
        if (re.test(fieldVal) || fieldVal === "") {
          this.setState(
            {
              otpFieldThree: fieldVal
            },
            () => {
              if (_.get(this, "state.otpFieldThree", "") !== "") {
                this.otpFieldFour.focus();
              }
              else {
                this.otpFieldTwo.focus();
              }
            }
          );
        }
        break;
      }
      case "otpFieldFour": {
        const re = /^[0-9\b]+$/;
        if (re.test(fieldVal) || fieldVal === "") {
          this.setState(
            {
              otpFieldFour: fieldVal
            },
            () => {
              if (_.get(this, "state.otpFieldFour", "") !== "") {
                this.otpFieldFive.focus();
              }
              else {
                this.otpFieldThree.focus();
              }
            }
          );
        }
        break;
      }
      case "otpFieldFive": {
        const re = /^[0-9\b]+$/;
        if (re.test(fieldVal) || fieldVal === "") {
          this.setState(
            {
              otpFieldFive: fieldVal
            },
            () => {
              if (_.get(this, "state.otpFieldFive", "") !== "") {
                this.otpFieldSix.focus();
              }
              else {
                this.otpFieldFour.focus();
              }
            },
            // () => {
            //   if (_.get(this, "state.otpFieldFive", "") == "") {
            //     this.otpFieldFour.focus();
            //   }
            // }
          );
        }
        break;
      }
      case "otpFieldSix": {
        const re = /^[0-9\b]+$/;
        if (re.test(fieldVal) || fieldVal === "") {
          this.setState(
            {
              otpFieldSix: fieldVal
            },
            () => {
              if (_.get(this, "state.otpFieldSix", "") == "") {
                this.otpFieldFive.focus();
              }
            }
            // () => {}
          );
        }
        break;
      }
      case "uaeID": {
        this.setState({
          emiratesIdNumber: fieldVal
        });
        break;
      }
      case "tenancyConact": {
        this.setState({
          tenancyConactNumber: fieldVal
        });
        break;
      }
      default: {
      }
    }
  };

  handleDayClick(date) {
    if (date === undefined) {
      this.setState(
        {
          isDateValid: false
        },
        () => {
          return false;
        }
      );
    } else {
      const d = new Date(date);
      let isDateValid = true;

      if (
        _.get(this, "props.chat.type", "") === "new_user_form" ||
        _.get(this, "props.chat.type", "") === "existing_user_form" ||
        _.get(this, "props.chat.range", "") === "past"
      ) {
        const now = new Date();
        now.setHours(0, 0, 0, 0);
        if (d >= now) {
          isDateValid = false;
        }
      }

      if (_.get(this, "props.chat.range", "") === "future") {
        const now = new Date();
        now.setHours(0, 0, 0, 0);
        if (now >= d) {
          isDateValid = false;
        }
      }

      this.setState(
        {
          dateValue: date,
          isDateValid: isDateValid
        },
        () => {
          if (isDateValid) {
            if (_.get(this, "props.chat.type", "") === "new_user_form") {
              this.generateStepsCount();
            }

            if (
              _.get(this, "props.chat.type", "") === "new_user_form" ||
              _.get(this, "props.chat.type", "") === "existing_user_form"
            ) {
              this.phoneNumberValue.focus();
            }

            if (_.get(this, "props.chat.type", "") === "date") {
              this.submitButton.focus();
            }
          }
        }
      );
    }
  }

  generateStepsCount() {
    let stepsCount = 0;
    // if (_.get(this, "state.isDateValid", false) === true) {
    if (
      _.get(this, "state.isDateDayValid", false) &&
      _.get(this, "state.isDateMonthValid", false) &&
      _.get(this, "state.isDateYearValid", false)
    ) {
      stepsCount++;
    }
    if (_.get(this, "state.isNameValid", false) === true) {
      stepsCount++;
    }
    if (_.get(this, "state.isPhoneNumberValid", false)) {
      stepsCount++;
    }
    if (_.get(this, "state.isEmailValid", false)) {
      stepsCount++;
    }
    this.setState({
      stepsCompleted: stepsCount <= 0 ? 0 : stepsCount
    });
  }

  isValidOrInvalid(stateValid, stateValue) {
    if (_.get(this.state, stateValue, "") !== "") {
      return !_.get(this.state, stateValid, false) ? "is-invalid" : "is-valid";
    }
    return "";
  }

  renderNameField() {
    const isformSubmitted = _.get(this, "state.formSubmitted", false);
    let opts = {};
    if (isformSubmitted) {
      opts["disabled"] = "disabled";
    }
    return (
      <div className="input-group mb-3">
        <label
          className={
            _.get(this, "props.isRTLFlow", false) === true ? "font-arabic" : ""
          }
        >
          {Translate("ENTER_NAME", _.get(this, "props.lang"))}
        </label>
        <input
          type="text"
          className={
            "form-control txt-name" +
            this.isValidOrInvalid("isNameValid", "nameValue") +
            (_.get(this, "props.isRTLFlow", false)
              ? "rounded-right "
              : "rounded-left ")
          }
          onChange={e => this.handleInputValue(e, "name")}
          value={_.get(this, "state.nameValue", "")}
          {...opts}
          autoComplete="off"
        />
        <div className={"input-group-append "}>
          <span
            className={
              "input-group-text fill_ic  " +
              (!_.get(this, "state.isNameValid", false)
                ? "arrow_ic_blue"
                : "tick_ic_green")
            }
          >
            <img
              src={
                !_.get(this, "state.isNameValid", false) ? arrowIcon : tickIcon
              }
              alt=""
            />
          </span>
        </div>
      </div>
    );
  }
  /*
  renderDateFieldOldVersion() {
    const today = new Date();
    const isformSubmitted = _.get(this, "state.formSubmitted", false);
    let opts = {};
    if(isformSubmitted) {
      opts['disabled'] = true;
    }
    if (
      _.get(this, "props.chat.type", "") === "new_user_form" ||
      _.get(this, "props.chat.type", "") === "existing_user_form" ||
      _.get(this, "props.chat.range", "") === "past"
    ) {
      opts['after']=today
    }

    if (_.get(this, "props.chat.range", "") === "future") {
      opts['before']=today
    }
    return (
      <div className="input-group mb-3">
        <label>
          {_.get(this, "props.chat.type", "") === "new_user_form" ||
          _.get(this, "props.chat.type", "") === "existing_user_form"
            ? Translate("DATE_OF_BIRTH", _.get(this, "props.lang"))           
            : Translate("DATE", _.get(this, "props.lang")) }
        </label>

        <div>
          <DayPickerInput
            {...opts}
            placeholder="DD/MM/YYYY"
            format="DD/MM/YYYY"
            formatDate={formatDate}
            dayPickerProps={{ disabledDays: opts }}
            // inputProps={{readonly: 'readonly'}}
            parseDate={parseDate} 
            dateFormat="DD/MM/YYYY"
            className={
              "rounded form-control" +
              _.get(this, "state.isDateValid", false) ? "is-valid" : "is-invalid"
            }
            readonly
            onDayChange={this.handleDayClick} 
          />
        </div>
        <div className="input-group-append">
          <span
            className={
              "input-group-text fill_ic tick_ic_green " +
              (!(
                _.get(this, "state.isDateValid", false) 
              )
                ? "arrow_ic_blue"
                : "tick_ic_green")
            }
          >
            <img src={(!_.get(this, "state.isDateValid", false) ? arrowIcon : tickIcon)} alt="" />
          </span>
        </div>
      </div>
    );
  }
  */

  renderDateField() {
    return (
      <div className="input-group mb-3 datefield-sector">
        <label
          className={
            _.get(this, "props.isRTLFlow", false) === true ? "font-arabic" : ""
          }
        >
          {_.get(this, "props.chat.type", "") === "new_user_form" ||
            _.get(this, "props.chat.type", "") === "existing_user_form"
            ? Translate("DATE_OF_BIRTH", _.get(this, "props.lang"))
            : Translate("DATE", _.get(this, "props.lang"))}
        </label>
        {_.get(this, "props.isRTLFlow", false) === false && (
          <React.Fragment>
            <div className="col-2 pl-1 pr-1">
              <input
                type="text"
                className={
                  "rounded form-control text-center " +
                  this.isValidOrInvalid("isDateDayValid", "dateDayValue")
                }
                placeholder="09"
                onChange={e => this.handleInputValue(e, "day")}
                value={_.get(this, "state.dateDayValue", "")}
                minLength="1"
                maxLength="2"
                ref={el => {
                  this.dateDayValue = el;
                }}
              />
              <label className="form-text float-left info-label">Day</label>
            </div>
            <div>
              <img src={slashIcon} alt="" />{" "}
            </div>
            <div className="col-2 pl-1 pr-1 ">
              <input
                type="text"
                className={
                  "rounded form-control text-center " +
                  this.isValidOrInvalid("isDateMonthValid", "dateMonthValue")
                }
                placeholder="09"
                onChange={e => this.handleInputValue(e, "month")}
                value={_.get(this, "state.dateMonthValue", "")}
                minLength="1"
                maxLength="2"
                ref={el => {
                  this.dateMonthValue = el;
                }}
              />
              <label className="form-text float-left info-label">Month</label>
            </div>
            <div>
              <img src={slashIcon} alt="" />{" "}
            </div>
            <div className="pl-1 pr-1 year">
              <input
                type="text"
                placeholder="2019"
                className={
                  "rounded form-control text-center " +
                  this.isValidOrInvalid("isDateYearValid", "dateYearValue")
                }
                onChange={e => this.handleInputValue(e, "year")}
                value={_.get(this, "state.dateYearValue", "")}
                minLength="4"
                maxLength="4"
                ref={el => {
                  this.dateYearValue = el;
                }}
              />
              <label className="form-text float-left info-label">Year</label>
            </div>
          </React.Fragment>
        )}
        {_.get(this, "props.isRTLFlow", false) === true && (
          <React.Fragment>
            <div className="col-2 pl-1 pr-1">
              <input
                type="text"
                className={
                  " rounded form-control text-center " +
                  this.isValidOrInvalid("isDateDayValid", "dateDayValue")
                }
                placeholder="09"
                onChange={e => this.handleInputValue(e, "day")}
                value={_.get(this, "state.dateDayValue", "")}
                minLength="1"
                maxLength="2"
                ref={el => {
                  this.dateDayValue = el;
                }}
              />
              <label className="form-text float-left info-label font-arabic">
                اليوم
              </label>
            </div>
            <div>
              <img src={slashIcon} alt="" />{" "}
            </div>
            <div className="col-2 pl-1 pr-1">
              <input
                type="text"
                className={
                  "rounded form-control text-center " +
                  this.isValidOrInvalid("isDateMonthValid", "dateMonthValue")
                }
                placeholder="09"
                onChange={e => this.handleInputValue(e, "month")}
                value={_.get(this, "state.dateMonthValue", "")}
                minLength="1"
                maxLength="2"
                ref={el => {
                  this.dateMonthValue = el;
                }}
              />
              <label className="form-text float-left info-label font-arabic">
                الشهر
              </label>
            </div>
            <div>
              <img src={slashIcon} alt="" />{" "}
            </div>
            <div className="pl-1 pr-1 year">
              <input
                type="text"
                placeholder="2019"
                className={
                  "rounded form-control text-center " +
                  this.isValidOrInvalid("isDateYearValid", "dateYearValue")
                }
                onChange={e => this.handleInputValue(e, "year")}
                value={_.get(this, "state.dateYearValue", "")}
                minLength="4"
                maxLength="4"
                ref={el => {
                  this.dateYearValue = el;
                }}
              />
              <label className="form-text float-left info-label font-arabic">
                السنة
              </label>
            </div>
          </React.Fragment>
        )}

        {/* <span className="col-1" /> */}
        {/*
      <div className="input-group-append">
        <span
          className={
            "input-group-text fill_ic tick_ic_green " +
            (!(
              _.get(this, "state.isDateDayValid", false) &&
              _.get(this, "state.isDateMonthValid", false) &&
              _.get(this, "state.isDateYearValid", false)
            )
              ? "hide"
              : "")
          }
        >
          <img src={tickIcon} alt="" />
        </span>
      </div>
      */}
        <div className="input-group-append">
          <span
            className={
              "input-group-text fill_ic tick_ic_green " +
              (!(
                _.get(this, "state.isDateDayValid", false) &&
                _.get(this, "state.isDateMonthValid", false) &&
                _.get(this, "state.isDateYearValid", false)
              )
                ? "arrow_ic_blue"
                : "tick_ic_green")
            }
          >
            <img
              src={
                !(
                  _.get(this, "state.isDateDayValid", false) &&
                  _.get(this, "state.isDateMonthValid", false) &&
                  _.get(this, "state.isDateYearValid", false)
                )
                  ? arrowIcon
                  : tickIcon
              }
              alt=""
            />
          </span>
        </div>
        {/* <label className={"date-error "+ (
           (
            (!_.get(this, "state.isDateDayValid", false) ||
            !_.get(this, "state.isDateMonthValid", false) ||
            !_.get(this, "state.isDateYearValid", false))
           ) ? "" : "hide"
         )}
      >
        {
          _.get(this, "props.chat.range", "") 
          === "future") ?
          Translate("ENTER_FUTURE_DATE", _.get(this, "props.lang")) : 
          ""
          Translate("ENTER_PAST_DATE", _.get(this, "props.lang"))           
        }        

      </label> */}
      </div>
    );
  }

  renderDateFieldNew() {
    const today = new Date();
    const state = this.state;
    const calendar = (
      <Calendar
        style={{ zIndex: 1000 }}
        disabledDate={this.disabledDate.bind(this)}
        format={multiFormats}
        defaultValue={this.props.defaultCalendarValue}
        showDateInput={state.showDateInput}
      />
    );
    const isformSubmitted = _.get(this, "state.formSubmitted", false);
    let opts = {};
    if (isformSubmitted) {
      opts["disabled"] = true;
    }
    if (
      _.get(this, "props.chat.type", "") === "new_user_form" ||
      _.get(this, "props.chat.type", "") === "existing_user_form" ||
      _.get(this, "props.chat.range", "") === "past"
    ) {
      opts["after"] = today;
    }

    if (_.get(this, "props.chat.range", "") === "future") {
      opts["before"] = today;
    }
    return (
      <div className="input-group mb-3">
        <label
          className={
            _.get(this, "props.isRTLFlow", false) === true ? "font-arabic" : ""
          }
        >
          {_.get(this, "props.chat.type", "") === "new_user_form" ||
            _.get(this, "props.chat.type", "") === "existing_user_form"
            ? Translate("DATE_OF_BIRTH", _.get(this, "props.lang"))
            : Translate("DATE", _.get(this, "props.lang"))}
        </label>

        <div
          style={{
            boxSizing: "border-box",
            position: "relative",
            display: "block",
            lineHeight: 1.5
          }}
        >
          <DatePicker
            animation="slide-up"
            calendar={calendar}
            value={state.value}
            onChange={this.onChange}
            readOnly
          >
            {({ value }) => {
              return (
                <span tabIndex="0">
                  <input
                    placeholder={
                      Translate("DD", _.get(this, "props.lang")) +
                      "/" +
                      Translate("MM", _.get(this, "props.lang")) +
                      "/" +
                      Translate("YYYY", _.get(this, "props.lang"))
                    }
                    style={{ width: 250 }}
                    disabled={state.disabled}
                    readOnly
                    tabIndex="-1"
                    className="ant-calendar-picker-input ant-input datedate"
                    value={
                      (value && value.format(getFormat(state.showTime))) || ""
                    }
                  />
                </span>
              );
            }}
          </DatePicker>
        </div>

        <div className="input-group-append">
          <span
            className={
              "input-group-text fill_ic tick_ic_green " +
              (!_.get(this, "state.isDateValid", false)
                ? "arrow_ic_blue"
                : "tick_ic_green")
            }
          >
            <img
              src={
                !_.get(this, "state.isDateValid", false) ? arrowIcon : tickIcon
              }
              alt=""
            />
          </span>
        </div>
      </div>
    );
  }

  renderPhoneField() {
    const isformSubmitted = _.get(this, "state.formSubmitted", false);
    let opts = {};
    if (isformSubmitted) {
      opts["disabled"] = "disabled";
    }
    return (
      <div className="input-group mb-3">
        <label
          className={_.get(this, "props.isRTLFlow", false) ? "font-arabic" : ""}
        >
          {Translate("MOBILE_NUMBER", _.get(this, "props.lang"))}
        </label>
        {_.get(this, "props.isRTLFlow", false) && (
          <React.Fragment>
            <input
              type="text"
              placeholder="XXXXX XXX"
              className={
                "col-8 rounded-right form-control " +
                this.isValidOrInvalid("isPhoneNumberValid", "phoneNumberValue")
              }
              onChange={e => this.handleInputValue(e, "phonenumber")}
              value={_.get(this, "state.phoneNumberValue", "")}
              maxLength="9"
              {...opts}
              autoComplete="off"
              ref={input => {
                this.phoneNumberValue = input;
              }}
            />
            <input
              type="text"
              placeholder="+971"
              className="col-3 mr-3 rounded-left form-control phone-control"
              disabled
              onChange={e => this.handleInputValue(e, "phonecode")}
              value={_.get(this, "state.phoneCodeValue", "")}
            />
          </React.Fragment>
        )}
        {_.get(this, "props.isRTLFlow", false) === false && (
          <React.Fragment>
            <input
              type="text"
              placeholder="+971"
              className="col-3 mr-3 rounded form-control phone-control"
              disabled
              onChange={e => this.handleInputValue(e, "phonecode")}
              value={_.get(this, "state.phoneCodeValue", "")}
            />
            <input
              type="text"
              placeholder="XXXXX XXX"
              className={
                "col-8 rounded form-control " +
                this.isValidOrInvalid("isPhoneNumberValid", "phoneNumberValue")
              }
              onChange={e => this.handleInputValue(e, "phonenumber")}
              value={_.get(this, "state.phoneNumberValue", "")}
              maxLength="9"
              {...opts}
              autoComplete="off"
              ref={input => {
                this.phoneNumberValue = input;
              }}
            />
          </React.Fragment>
        )}

        <div className="input-group-append">
          <span
            className={
              "input-group-text fill_ic  " +
              (!_.get(this, "state.isPhoneNumberValid", false)
                ? "arrow_ic_blue"
                : "tick_ic_green")
            }
          >
            <img
              src={
                !_.get(this, "state.isPhoneNumberValid", false)
                  ? arrowIcon
                  : tickIcon
              }
              alt=""
            />
          </span>
        </div>
      </div>
    );
  }

  renderEmailField() {
    const isformSubmitted = _.get(this, "state.formSubmitted", false);
    let opts = {};
    if (isformSubmitted) {
      opts["disabled"] = "disabled";
    }
    return (
      <div className="input-group mb-3">
        <label
          className={
            _.get(this, "props.isRTLFlow", false) === true ? "font-arabic" : ""
          }
        >
          {Translate("EMAIL_ID", _.get(this, "props.lang"))}
        </label>
        <input
          {...opts}
          type="text"
          maxLength="255"
          className={
            "rounded form-control email-field " +
            (_.get(this, "props.isRTLFlow", false)
              ? "rounded-right "
              : "rounded-left ") +
            this.isValidOrInvalid("isEmailValid", "emailValue")
          }
          onChange={e => this.handleInputValue(e, "email")}
          value={_.get(this, "state.emailValue", "")}
          autoComplete="off"
          ref={input => {
            this.emailField = input;
          }}
        />
        <div className={"input-group-append"}>
          <span
            className={
              "input-group-text fill_ic " +
              (!_.get(this, "state.isEmailValid", false)
                ? "arrow_ic_blue"
                : "tick_ic_green")
            }
          >
            <img
              src={
                !_.get(this, "state.isEmailValid", false) ? arrowIcon : tickIcon
              }
              alt=""
            />
          </span>
        </div>
      </div>
    );
  }

  renderOTPField() {
    const isformSubmitted = _.get(this, "state.formSubmitted", false);
    let opts = {};
    if (isformSubmitted) {
      opts["disabled"] = "disabled";
    }
    return (
      <div className="input-group mb-3 otp-top-line">
        <div className="col pb-4 pl-1 pr-1 pt-0 otp-container">
          <label
            className={
              "text-center mt-2 hide " +
              (_.get(this, "props.isRTLFlow", false) === false
                ? "font-arabic"
                : "")
            }
          >
            Please enter the One-Time Password
          </label>
          <div className="form-row">
            <input
              {...opts}
              type="text"
              className="form-control col mt-3 m-1  fill-otp text-center"
              placeholder=""
              onChange={e => this.handleInputValue(e, "otpFieldOne")}
              maxLength="1"
              value={_.get(this, "state.otpFieldOne", "")}
              autoComplete="off"
              autoFocus
              ref={input => {
                this.otpFieldOne = input;
              }}
            />

            <input
              {...opts}
              type="text"
              className="form-control col mt-3 m-1  fill-otp text-center"
              placeholder=""
              onChange={e => this.handleInputValue(e, "otpFieldTwo")}
              maxLength="1"
              value={_.get(this, "state.otpFieldTwo", "")}
              autoComplete="off"
              ref={input => {
                this.otpFieldTwo = input;
              }}
            />

            <input
              {...opts}
              type="text"
              className="form-control col mt-3 m-1  fill-otp text-center"
              placeholder=""
              onChange={e => this.handleInputValue(e, "otpFieldThree")}
              maxLength="1"
              value={_.get(this, "state.otpFieldThree", "")}
              autoComplete="off"
              ref={input => {
                this.otpFieldThree = input;
              }}
            />

            <input
              {...opts}
              type="text"
              className="form-control col mt-3 m-1  fill-otp text-center"
              placeholder=""
              onChange={e => this.handleInputValue(e, "otpFieldFour")}
              maxLength="1"
              value={_.get(this, "state.otpFieldFour", "")}
              autoComplete="off"
              ref={input => {
                this.otpFieldFour = input;
              }}
            />

            <input
              {...opts}
              type="text"
              className="form-control col mt-3 m-1  fill-otp text-center"
              placeholder=""
              onChange={e => this.handleInputValue(e, "otpFieldFive")}
              maxLength="1"
              value={_.get(this, "state.otpFieldFive", "")}
              autoComplete="off"
              ref={input => {
                this.otpFieldFive = input;
              }}
            />

            <input
              {...opts}
              type="text"
              className="form-control col mt-3 m-1  fill-otp text-center"
              placeholder=""
              onChange={e => this.handleInputValue(e, "otpFieldSix")}
              maxLength="1"
              value={_.get(this, "state.otpFieldSix", "")}
              autoComplete="off"
              ref={input => {
                this.otpFieldSix = input;
              }}
            />
          </div>

          <div className="col pt-2 pb-0">
            <div className="row">
              <button
                disabled={
                  _.get(this, "props.disablebutton", true)
                    ? "disabled"
                    : ""
                }
                {...opts}
                type="button"
                className="btn btn-link btn-block btn-form-link"
                onClick={() => this.rensendOTP()}
              >
                {Translate("RESEND", _.get(this, "props.lang", "English"))}
              </button>
              <button
                disabled={
                  _.get(this, "props.disablebutton", true)
                    ? "disabled"
                    : ""
                }
                {...opts}
                type="button"
                className={
                  "btn btn-block btn-text-white " +
                  (this.isFormValidate() === true ? " btn-blue " : "") +
                  (_.get(this, "props.isRTLFlow", false) === true
                    ? "font-arabic"
                    : "")
                }
                onClick={() => this.submitForm()}
              >
                {Translate("SUBMIT", _.get(this, "props.lang", "English"))}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderAuthForm() {
    return (
      <div>
        <div className="input-group mb-3">
          <input
            type="text"
            className="form-control"
            placeholder={
              _.get(this, "props.isRTLFlow", false)
                ? "رقم الهوية"
                : "Emirates ID Number"
            }
            onChange={e => this.handleInputValue(e, "uaeID")}
            value={_.get(this, "state.emiratesIdNumber", "")}
          />
          <div
            className={
              "input-group-append " +
              (_.get(this, "state.emiratesIdNumber", "") === "" ? "hide" : "")
            }
          >
            <span className="input-group-text fill_ic tick_ic_green">
              <img src={tickIcon} alt="" />
            </span>
          </div>
        </div>
        <div className="input-group mb-3">
          <input
            type="text"
            className="form-control"
            placeholder={
              _.get(this, "props.isRTLFlow", false)
                ? "رقم عقد الإيجار"
                : "Tenancy Contact Number"
            }
            onChange={e => this.handleInputValue(e, "tenancyConact")}
            value={_.get(this, "state.tenancyConactNumber", "")}
          />
          <div
            className={
              "input-group-append " +
              (_.get(this, "state.tenancyConactNumber", "") === ""
                ? "hide"
                : "")
            }
          >
            <span className="input-group-text fill_ic tick_ic_green">
              <img src={tickIcon} alt="" />
            </span>
          </div>
        </div>
      </div>
    );
  }

  isFormValidate() {
    if (_.get(this, "props.chat.type", "") === "date") {
      if (
        //_.get(this, "state.isDateValid", false)
        _.get(this, "state.isDateDayValid", false) &&
        _.get(this, "state.isDateMonthValid", false) &&
        _.get(this, "state.isDateYearValid", false)
      ) {
        return true;
      }
      return false;
    }
    if (
      _.get(this, "props.chat.type", "") === "new_user_form" &&
      _.get(this, "state.stepsCompleted", 0) === 4
    ) {
      return true;
    }
    if (_.get(this, "props.chat.type", "") === "otp") {
      if (
        _.get(this, "state.otpFieldOne", "") !== "" &&
        _.get(this, "state.otpFieldTwo", "") !== "" &&
        _.get(this, "state.otpFieldThree", "") !== "" &&
        _.get(this, "state.otpFieldFour", "") !== "" &&
        _.get(this, "state.otpFieldFive", "") !== "" &&
        _.get(this, "state.otpFieldSix", "")
      ) {
        return true;
      }
    }
    if (
      _.get(this, "props.chat.type", "") === "auth_form" &&
      (_.get(this, "state.emiratesIdNumber", "") !== "" &&
        _.get(this, "state.tenancyConactNumber", "") !== "")
    ) {
      return true;
    }
    if (_.get(this, "props.chat.type", "") === "existing_user_form") {
      if (
        //_.get(this, "state.isDateValid", false) &&
        _.get(this, "state.isDateDayValid", false) &&
        _.get(this, "state.isDateMonthValid", false) &&
        _.get(this, "state.isDateYearValid", false) &&
        _.get(this, "state.isPhoneNumberValid", false)
      ) {
        return true;
      }
      return false;
    }
    if (_.get(this, "props.chat.type", "") === "callback_form") {
      if (
        (_.get(this, "state.isNameValid", false) === true) &&
        (_.get(this, "state.isPhoneNumberValid", false)) &&
        (_.get(this, "state.isEmailValid", false))
      ) {
        return true;
      }
      return false;
    }
    if (_.get(this, "props.chat.type", "") === "get_user_name") {
      if (
        (_.get(this, "state.isNameValid", false) === true)
      ) {
        return true;
      }
      return false;
    }

    return false;
  }

  rensendOTP() {
    this.props.userFormReply("/resend", false);
  }
  submitForm() {
    if (this.isFormValidate()) {
      if (_.get(this, "props.chat.type", "") === "date") {
        this.setState(
          {
            formSubmitted: true
          },
          () => {
            const date =
              _.get(this, "state.dateYearValue", "") +
              "/" +
              _.get(this, "state.dateMonthValue", "") +
              "/" +
              _.get(this, "state.dateDayValue", "");

            // this.props.formReply(
            //   dateToDBFormat(_.get(this, "state.dateValue", ""))
            // );
            this.props.multiLangQuickReply(
              JSON.stringify({
                title: dateToDispFormat(date),
                payload: dateToDBFormat(date)
              })
            );
          }
        );
      }
      if (_.get(this, "props.chat.type", "") === "new_user_form") {
        if (_.get(this, "state.formSubmitted", false) === false) {
          this.setState(
            {
              formSubmitted: true
            },
            () => {
              const date =
                _.get(this, "state.dateYearValue", "") +
                "-" +
                _.get(this, "state.dateMonthValue", "") +
                "-" +
                _.get(this, "state.dateDayValue", "");

              this.props.userFormReply(
                JSON.stringify({
                  dob: dateToDBFormat(date),
                  name: _.get(this, "state.nameValue", ""),
                  phone: "971" + _.get(this, "state.phoneNumberValue", ""),
                  email: _.get(this, "state.emailValue", "")
                })
              );
            }
          );
        }
      }
      if (_.get(this, "props.chat.type", "") === "existing_user_form") {
        if (_.get(this, "state.formSubmitted", false) === false) {
          this.setState(
            {
              formSubmitted: true
            },
            () => {
              const date =
                _.get(this, "state.dateYearValue", "") +
                "-" +
                _.get(this, "state.dateMonthValue", "") +
                "-" +
                _.get(this, "state.dateDayValue", "");

              this.props.userFormReply(
                JSON.stringify({
                  dob: dateToDBFormat(date),
                  phone: "971" + _.get(this, "state.phoneNumberValue", "")
                })
              );
            }
          );
        }
      }
      if (_.get(this, "props.chat.type", "") === "callback_form") {
        if (_.get(this, "state.formSubmitted", false) === false) {
          this.setState(
            {
              formSubmitted: true
            },
            () => {
              this.props.userFormReply(
                JSON.stringify({
                  name: _.get(this, "state.nameValue", ""),
                  phone: "971" + _.get(this, "state.phoneNumberValue", ""),
                  email: _.get(this, "state.emailValue", "")
                })
              );
            }
          );
        }
      }
      if (_.get(this, "props.chat.type", "") === "auth_form") {
        if (_.get(this, "state.formSubmitted", false) === false) {
          this.setState(
            {
              formSubmitted: true
            },
            () => {
              this.props.userFormReply(
                JSON.stringify({
                  emirates_id: _.get(this, "state.emiratesIdNumber", ""),
                  tenancy_contract_number: _.get(
                    this,
                    "state.tenancyConactNumber",
                    ""
                  )
                })
              );
            }
          );
        }
      }
      if (_.get(this, "props.chat.type", "") === "otp") {
        this.setState(
          {
            formSubmitted: true
          },
          () => {
            this.props.userFormReply("/submit_otp{\"otp_code\": \"" +
              _.get(this, "state.otpFieldOne", "") +
              _.get(this, "state.otpFieldTwo", "") +
              _.get(this, "state.otpFieldThree", "") +
              _.get(this, "state.otpFieldFour", "") +
              _.get(this, "state.otpFieldFive", "") +
              _.get(this, "state.otpFieldSix", "") + "\"}", false
            );
          }
        );
      }
      if (_.get(this, "props.chat.type", "") === "get_user_name") {
        this.setState({
          formSubmitted: true
        }, () => {
          this.props.userFormReply(
            _.get(this, "state.nameValue", ""),
          );
        }
        );
      }
      if (_.get(this, "props.chat.type", "") === "get_user_name") {
        this.setState({
          formSubmitted: true
        }, () => {
          this.props.userFormReply(
            _.get(this, "state.nameValue", ""),
          );
        }
        );
      }
    }
  }

  render() {
    const chat = this.props.chat;
    const isformSubmitted = _.get(this, "state.formSubmitted", false);
    let opts = {};
    if (isformSubmitted) {
      opts["disabled"] = "disabled";
    }
    return (
      <div className="form-main">
        {/* <div
          className={"steps " + (chat.type !== "new_user_form" ? "hide" : "")}
        >
          <ul>
            <li
              className={
                _.get(this, "state.stepsCompleted", 0) > 0 ? "active" : ""
              }
            />
            <li
              className={
                _.get(this, "state.stepsCompleted", 0) > 1 ? "active" : ""
              }
            />
            <li
              className={
                _.get(this, "state.stepsCompleted", 0) > 2 ? "active" : ""
              }
            />
            <li
              className={
                _.get(this, "state.stepsCompleted", 0) > 3 ? "active" : ""
              }
            />
          </ul>
          <span className="steps-digit pl-4 pr-4 pt-0 float-left">
            ({_.get(this, "state.stepsCompleted", 0)} of 4)
          </span>
        </div> */}
        <form
          className={
            (chat.type === "auth_form" ||
              chat.type === "date" ||
              chat.type === "otp" ||
              chat.type === "new_user_form" ||
              chat.type === "existing_user_form") &&
              ENVIORMENT.project !== "medcare"
              ? "form-header"
              : (chat.type !== "otp" ? "pb-4" : "") + " pl-4 pr-4 pt-0"
          }
        >
          {chat.type === "date" && this.renderDateField()}
          {chat.type === "get_user_name" && this.renderNameField()}
          {chat.type === "new_user_form" && this.renderNameField()}
          {chat.type === "new_user_form" && this.renderDateField()}
          {chat.type === "new_user_form" && this.renderPhoneField()}
          {chat.type === "new_user_form" && this.renderEmailField()}
          {chat.type === "existing_user_form" && this.renderDateField()}
          {chat.type === "existing_user_form" && this.renderPhoneField()}
          {chat.type === "callback_form" && this.renderNameField()}
          {chat.type === "callback_form" && this.renderPhoneField()}
          {chat.type === "callback_form" && this.renderEmailField()}
          {chat.type === "otp" && this.renderOTPField()}
          {chat.type === "auth_form" && this.renderAuthForm()}
          {(chat.type === "auth_form" ||
            chat.type === "date" ||
            chat.type === "new_user_form" ||
            chat.type === "callback_form" ||
            chat.type === "get_user_name" ||
            chat.type === "existing_user_form") && (
              <button
                disabled={
                  _.get(this, "props.disablebutton", true)
                    ? "disabled"
                    : ""
                }
                type="button"
                {...opts}
                className={
                  "btn mt-4 btn-block btn-text-white " +
                  (this.isFormValidate() === true ? " btn-blue " : "") +
                  (_.get(this, "props.isRTLFlow", false) === true
                    ? "font-arabic-light"
                    : "")
                }
                onClick={() => {
                  this.submitForm();
                }}
                ref={input => {
                  this.submitButton = input;
                }}
              >
                {Translate("SUBMIT", _.get(this, "props.lang", "English"))}
              </button>
            )}
        </form>
      </div>
    );
  }
}

export default FormGenerate;
