import React from "react";
import _ from "lodash";

import { ENVIORMENT } from "./../../env";

import "./quick-reply.scss";
import FormGenerate from "./../FormGenerate/FormGenerate";
import Slider from "./../Slider/Slider";
import Sliderb from "./../Slider/Sliderb";
import AlAlain from "./../AlAlain/AlAlain";
import { guid } from "./../utils/Utility";
import Autosuggest from "./Autosuggest";
import Newautosuggest from "./Newautosuggest";
import Temp from "./Temp";
import DayamicAutosuggest from "./DaynamicAutosuggest";
import List from "./List";
import GetUserLocation from "./GetUserLocation";
import MultiSelect from "./MultiSelect";
import RangeSlider from "./../RangeSlider/RangeSlider";
import DaynamicForm from "./../DaynamicForm";
import SessionForm from "./../SessionForm";
import axios from "axios";


class BotHandler extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: -1,
      isMapOpen: false,
      isAgentOpened: false,
      datasearch: []
    };
    this.formReply = this.formReply.bind(this);
    this.maskReply = this.maskReply.bind(this);
    this.multiLangQuickReply = this.multiLangQuickReply.bind(this);
    this.userFormReply = this.userFormReply.bind(this);
    this.changeLanguage = this.changeLanguage.bind(this);
  }

  componentDidMount() {
    
    // let data = '';
    // this.lastRequestId = axios.post("https://production.medcare.cf/sanjay/public/api/getDoctorsList",data,
    //   )
    //   .then(res => {
    //     {
    //       console.log(res);
    //       _.map(res.data.data, title => {
    //         this.setState({
    //           datasearch: [...this.state.datasearch, title.title]
    //         });
    //       });
    //     }
    //   });
  }

  componentDidUpdate() {}

  formReply(formValue) {    
    this.props.sendQuickReply(formValue);
  }

  maskReply(displayMessage,payload){
    this.props.maskReply(displayMessage, payload);
  }

  multiLangQuickReply(formValue) {
    this.props.sendMultiLangQuickReply(formValue);
  }

  userFormReply(formValue) {
    this.props.sendQuickReply(formValue, false);
  }

  downloadFile(request) {
    const url = ENVIORMENT.download_url + "?filename=" + request.filename;
    return (
      <div className="bot">
        <div className="bot-avatar">
          <img
            src={require("./../../public/images/bot-avatar-" +
              _.get(this, "props.avatar", "") +
              ".png")}
            alt=""
          />
          <span className="status-online"> </span>
        </div>
        <div className="bot-msg">
          <span className="float-left" style={{ clear: "both" }} />
          <div>
            <span className="float-left" style={{ clear: "both" }} />
            <button
              className="btn btn-sm m-1 btn-border-bbl pull-right"
              onClick={event => {
                event.preventDefault();
                window.open(url);
              }}
            >
              Download
            </button>
          </div>
        </div>
      </div>
    );
  }

  openMap(url) {
    return (
      <div className="bot">
        <div className="bot-avatar">
          <img
            src={require("./../../public/images/bot-avatar-" +
              _.get(this, "props.avatar", "") +
              ".png")}
            alt=""
          />
          <span className="status-online"> </span>
        </div>
        <div className="bot-msg">
          <span className="float-left" style={{ clear: "both" }} />
          <div>
            <span className="float-left" style={{ clear: "both" }} />
            <button
              className="btn btn-sm m-1 btn-border-bbl pull-right"
              onClick={event => {
                event.preventDefault();
                window.open(url);
              }}
            >
              Open Direction Map
            </button>
          </div>
        </div>
      </div>
    );
  }

  changeLanguage(lang) {
    this.props.changeLanguage(lang);
  }

  loadBigAvatar() {
    return (
      <div className="load_avtar">
        <img
          src={require(`./../../public/images/big-avatar/${_.get(
            this,
            "props.avatar",
            ""
          )}.png`)}
          alt=""
        />
      </div>
    );
  }

  loadImage() {
    const project = ENVIORMENT.project;
    return (
      <div className="bot mb-2">
        <div className="bot-msg">
          <img
            src={
              _.get(this, "props.chat.data[0].image_type", "") === "name"
                ? require(`./../../public/images/image-component/${project}/${_.get(
                    this,
                    "props.chat.data[0].image",
                    ""
                  )}`)
                : _.get(this, "props.chat.data[0].image", "")
            }
            alt=""
            className="general-img"
          />
        </div>
      </div>
    );
  }

  removeGmap() {
    try {
      if (document.getElementById("openGMap")) {
        setTimeout(document.getElementById("openGMap").remove(), 500);
      }
    } catch (e) {}
  }

  chatWithLiveAngent() {    
    return (
      <div className="bot">
        <div className="bot-avatar">
          <img
            src={require("./../../public/images/bot-avatar-" +
              _.get(this, "props.avatar", "") +
              ".png")}
            alt=""
          />
          <span className="status-online" />
        </div>
        <div className="bot-msg">
          <span className="float-left" style={{ clear: "both" }} />
          <div>
            <span className="float-left" style={{ clear: "both" }} />
            <button className="btn btn-sm m-1 btn-border-bbl pull-right" onClick={ (e) =>{
              this.props.sendQuickReply("Live Agent", false);
            }}>
              Chat with Live Agent
            </button>
          </div>
        </div>
      </div>
    );
  }

  connectWithLiveAgent() {
    if(!_.get(this, "state.isAgentOpened", false)) {
      this.setState({
        isAgentOpened: true
      }, () => {
        this.props.toggleLiveAgent();
      })
    }
    return false;
  }

  render() {
    // console.log(this.props.search);
    if (_.get(this, "props.chat.type", "") === "get_user_location") {
      return (
        <GetUserLocation
          disablebutton={_.get(this, "props.disablebutton")}
          chat={this.props.chat.content}
          userFormReply={this.userFormReply}
          formReply={this.formReply}
          isRTLFlow={_.get(this, "props.isRTLFlow", false)}
          lang={_.get(this, "props.lang", "English")}
        />
      );
    }

    if (_.get(this, "props.chat.type", "") === "download") {
      return this.downloadFile(_.get(this, "props.chat.data[0]"));
    }

    if (_.get(this, "props.chat.type", "") === "image") {
      return this.loadImage();
    }

    if (_.get(this, "props.chat.type", "") === "chat_with_liveagent") {
      return this.chatWithLiveAngent();
    }

    if (_.get(this, "props.chat.type", "") === "chat_transcript") {
      return this.connectWithLiveAgent();
    }

    if (_.get(this, "props.chat.type", "") === "daynamic_form") {
      return <DaynamicForm
        disablebutton={_.get(this, "props.disablebutton")}
        data={this.props.chat.data}
        formReply={this.formReply}
        isRTLFlow={_.get(this, "props.isRTLFlow", false)}
        multiLangQuickReply={this.multiLangQuickReply}
        lang={_.get(this, "props.lang", "English")}
        userFormReply={this.userFormReply}
      />;
    }

    if (_.get(this, "props.chat.type", "") === "end_session") {
      return <SessionForm
        disablebutton={_.get(this, "props.disablebutton")}
        data={this.props.chat}
        formReply={this.formReply}
        isRTLFlow={_.get(this, "props.isRTLFlow", false)}
        multiLangQuickReply={this.multiLangQuickReply}
        lang={_.get(this, "props.lang", "English")}
        userFormReply={this.userFormReply}
      />;
    }
    if (_.get(this, "props.chat.type", "") === "restart_session") {
      return <SessionForm
        disablebutton={_.get(this, "props.disablebutton")}
        data={this.props.chat}
        formReply={this.formReply}
        isRTLFlow={_.get(this, "props.isRTLFlow", false)}
        multiLangQuickReply={this.multiLangQuickReply}
        lang={_.get(this, "props.lang", "English")}
        userFormReply={this.userFormReply}
      />;
    }
    

    if (_.get(this, "props.chat.type", "") === "get_map_direction") {
      return (
        <button
          ref={el => {
            this.openMap = el;
          }}
          id="openGMap"
          onClick={event => {
            event.preventDefault();
            if (!this.state.isMapOpen) {
              this.setState(
                {
                  isMapOpen: true
                },
                () => {
                  const url =
                    "https://www.google.com/maps/dir/" +
                    _.get(this, "props.chat.data[0].from.lat", "") +
                    "," +
                    _.get(this, "props.chat.data[0].from.lng", "") +
                    "/" +
                    _.get(this, "props.chat.data[0].to.lat", "") +
                    "," +
                    _.get(this, "props.chat.data[0].to.lng", "") +
                    "/";
                    let open = window.open(url);                   
                    if (open == null || typeof(open)=='undefined')
                        alert("We attempted to launch direction map in a new window, but a popup blocker is preventing it from opening. Please disable popup blockers.");

                }
              );
            }

            let element = document.getElementById("openGMap");
            element.disbled = false;
          }}
          className="hide"
        >
          {setTimeout(function() {
            try {
              if (document.getElementById("openGMap")) {
                document.getElementById("openGMap").click();
              }
            } catch (e) {}
          }, 250)}
        </button>
      );
    }

    if (_.get(this, "props.chat.type", "") === "list") {
      return (
        <List
          disablebutton={_.get(this, "props.disablebutton")}
          chat={this.props.chat}
          formReply={this.formReply}
          multiLangQuickReply={this.multiLangQuickReply}
          isRTLFlow={_.get(this, "props.isRTLFlow", false)}
          lang={_.get(this, "props.lang", "English")}
        />
      );
    }
    
    if (_.get(this, "props.chat.type", "") === "search_bar") {
      return (
        <Autosuggest
          disablebutton={_.get(this, "props.disablebutton")}
          chat={this.props.chat}
          formReply={this.formReply}
          isRTLFlow={_.get(this, "props.isRTLFlow", false)}
          lang={_.get(this, "props.lang", "English")}
        />
      );
    }

      if (_.get(this, "props.chat.type", "") === "search_bar_new") {
        return (
          <Newautosuggest
            disablebutton={_.get(this, "props.disablebutton")}
            chat={this.props.chat}
            data={this.props.search}
            arabicdatasearch={this.props.arabicdatasearch}
            formReply={this.formReply}
            isRTLFlow={_.get(this, "props.isRTLFlow", false)}
            lang={_.get(this, "props.lang", "English")}
          />
        );
      }

    if (_.get(this, "props.chat.type", "") === "temp") {
      return (
        <Temp
          disablebutton={_.get(this, "props.disablebutton")}
          chat={this.props.chat}
          formReply={this.formReply}
          isRTLFlow={_.get(this, "props.isRTLFlow", false)}
          lang={_.get(this, "props.lang", "English")}
        />
      );
    }

    if (_.get(this, "props.chat.type", "") === "daynamic_search_bar") {
      return (
        <DayamicAutosuggest
          disablebutton={_.get(this, "props.disablebutton")}
          chat={this.props.chat}
          formReply={this.formReply}
          multiLangQuickReply={this.multiLangQuickReply}
          isRTLFlow={_.get(this, "props.isRTLFlow", false)}
          lang={_.get(this, "props.lang", "English")}
        />
      );
    }

    if (
      _.get(this, "props.chat.type", "") === "otp" ||
      _.get(this, "props.chat.type", "") === "date"
    ) {
      return (
        <FormGenerate
          disablebutton={_.get(this, "props.disablebutton")}
          chat={this.props.chat}
          formReply={this.formReply}
          isRTLFlow={_.get(this, "props.isRTLFlow", false)}
          multiLangQuickReply={this.multiLangQuickReply}
          lang={_.get(this, "props.lang", "English")}
          userFormReply={this.userFormReply}
        />
      );
    }

    if (
      _.get(this, "props.chat.type", "") === "new_user_form" ||
      _.get(this, "props.chat.type", "") === "existing_user_form" ||
      _.get(this, "props.chat.type", "") === "auth_form" ||
      _.get(this, "props.chat.type", "") === "callback_form" ||
      _.get(this, "props.chat.type", "") === "get_user_name"
    ) {
      return (
        <FormGenerate
          disablebutton={_.get(this, "props.disablebutton")}
          chat={this.props.chat}
          userFormReply={this.userFormReply}
          isRTLFlow={_.get(this, "props.isRTLFlow", false)}
          lang={_.get(this, "props.lang", "English")}
        />
      );
    }

    if (
      _.get(this, "props.chat.type", "") === "alain_payment_form" ||
      _.get(this, "props.chat.type", "") === "edit_contract_form"
    ) {
      return <AlAlain chat={_.get(this, "props.chat", {})} />;
    }

    if (_.get(this, "props.chat.type", "") === "carousel") {
      return (
        <Slider
          key={guid()}
          disablebutton={_.get(this, "props.disablebutton")}
          sliderData={_.get(this, "props.chat", [])}
          txtmsg={_.get(this, "props.txtmsg", '')}
          multiLangQuickReply={this.multiLangQuickReply}
          isRTLFlow={_.get(this, "props.isRTLFlow", false)}
          lang={_.get(this, "props.lang", "English")}
        />
      );
    }
    if (_.get(this, "props.chat.type", "") === "carousel-button") {
      return (
        <Sliderb
          disablebutton={_.get(this, "props.disablebutton")}
          sliderData={_.get(this, "props.chat", [])}
          txtmsg={_.get(this, "props.txtmsg", '')}
          multiLangQuickReply={this.multiLangQuickReply}
          isRTLFlow={_.get(this, "props.isRTLFlow", false)}
          lang={_.get(this, "props.lang", "English")}
        />
      );
    }
    if (_.get(this, "props.chat.type", "") === "range_slider") {
      return (
        <RangeSlider
          disablebutton={_.get(this, "props.disablebutton")}
          chat={_.get(this, "props.chat", {})}
          formReply={this.formReply}
          maskReply={this.maskReply}
        />
      );
    }

    if (_.get(this, "props.chat.type", "") === "load_big_avatar") {
      return this.loadBigAvatar();
    }

    
    return (
      <MultiSelect
        disablebutton={_.get(this, "props.disablebutton")}
        disablebutton={_.get(this, "props.disablebutton")}
        chat={_.get(this, "props.chat")}
        avatar={_.get(this, "props.avatar", "")}
        userFormReply={this.userFormReply}
        formReply={this.formReply}
        changeLanguage={this.changeLanguage}
        multiLangQuickReply={this.multiLangQuickReply}
        isRTLFlow={_.get(this, "props.isRTLFlow", false)}
        lang={_.get(this, "props.lang", "English")}
      />
    );
  }
}

export default BotHandler;
