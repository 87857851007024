import React, { Component } from "react";
import _ from "lodash";
import "./index.scss";
import $ from "jquery";
import { guid } from "./../utils/Utility";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import slashIcon from "./../../public/images/date-slash.png";

// import { FilePond } from "react-filepond";
// import "filepond/dist/filepond.min.css";
const maxDate = new Date();

export default class DaynamicForm extends Component {
  emailvalue = true;
  emailvalue1 = "";

  numbervalue = true;
  numbervalue1 = "";

  phonevalue = true;
  phonevalue1 = "";

  passwordvalue = true;
  passwordvalue1 = "";
  char = true;
  otpvalue = true;
  otpvalue1 = "";
  otpvalue = true;
  otpvalue1 = "";
  constructor(props) {
    super(props);
    this.state = {
      formSubmitted: false,
      fields: [],
      submit_val: "",
      dateDayValue: "",
      dateMonthValue: "",
      dateYearValue: "",
      hourselect: "",
      minuteselect: "",
      secondselect: "AM",
      checked: true,
      tech: "select",
      expanded: false,
      radiovalue: "",
      checkvalue: [],
      otp: [],
      selectedFile: null,
      loaded: 0,
      maxDates: new Date(maxDate.setFullYear(maxDate.getFullYear() + 1))
    };
  }

  componentDidMount() {
    function myFunction() {}
    let fields = this.props.data.fields;
    _.map(fields, field => {
      _.set(field, "key", guid());

      if (_.has(field, "value")) {
        _.set(field, "isValid", false);
      }
      if (field.type === "otp") {
        this.setState({
          otp: new Array(field.no_of_fields)
        });
      }
    });
    this.setState({
      fields: this.props.data.fields
    });
  }

  renderTextField(field) {
    const isformSubmitted = _.get(this, "state.formSubmitted", false);
    let opts = {};
    if (
      field.required == false ||
      field.required == undefined ||
      field.required == undefined
    ) {
      field.isValid = true;
    }
    let email = field.value;

    if (email.match(/^[a-zA-Z\s]+$/g) == null) {
      if (email == "") {
        this.char = true;
      } else {
        this.char = false;
      }
      field.isValid = false;
    } else {
      field.isValid = true;
    }
    if (isformSubmitted) {
      opts["disabled"] = "disabled";
    }

    return (
      <div className="input-group mb-3" key={field.key}>
        <label
          className={
            _.get(this, "props.isRTLFlow", false) === true ? "font-arabic" : ""
          }
        >
          {_.get(field, "label", "")}
          {field.required ? <span className="required">*</span> : ""}
          <span className="text-right pull-right" style={{ color: "red" }}>
            {this.char == false ? <span>Please Enter Vaid Data</span> : ""}
          </span>
        </label>
        <input
          disabled={_.get(this, "props.disablebutton", true) ? "disabled" : ""}
          maxLength="255"
          required
          {...opts}
          type="text"
          className={
            "rounded form-control email-field " +
            (_.get(this, "props.isRTLFlow", false)
              ? "rounded-right "
              : "rounded-left ")
          }
          value={_.get(field, "value", "")}
          onChange={e => {
            let data = this.state.fields;
            for (let i = 0; i < data.length; i++) {
              if (data[i].key === field.key) {
                let email = data[i].value;
                if (data[i].required && e.target.value === "") {
                  data[i].isValid = false;
                } else if (!email.match(/^[a-zA-Z]+/m)) {
                  this.char = false;
                  data[i].isValid = false;
                } else {
                  this.char = true;
                  data[i].isValid = true;
                }

                data[i].value = e.target.value;
              }
            }
            this.setState({
              fields: data
            });
          }}
          autoComplete="off"
        />
      </div>
    );
  }

  renderNumericField(field) {
    const isformSubmitted = _.get(this, "state.formSubmitted", false);
    if (field.required == false || field.required == undefined) {
      field.isValid = true;
    }
    let opts = {};
    if (isformSubmitted) {
      opts["disabled"] = "disabled";
    }
    return (
      <div className="input-group mb-3" key={field.key}>
        <label
          className={
            _.get(this, "props.isRTLFlow", false) === true ? "font-arabic" : ""
          }
        >
          {_.get(field, "label", "")}
          {field.required ? <span className="required">*</span> : ""}

          <span className="text-right pull-right" style={{ color: "red" }}>
            {!this.numbervalue && this.numbervalue1 ? (
              <span>Please enter a your valid value</span>
            ) : (
              ""
            )}
          </span>
        </label>
        <input
          disabled={_.get(this, "props.disablebutton", true) ? "disabled" : ""}
          {...opts}
          type="text"
          maxLength="255"
          className={
            "rounded form-control email-field " +
            (_.get(this, "props.isRTLFlow", false)
              ? "rounded-right "
              : "rounded-left ")
          }
          autoComplete="off"
          value={_.get(field, "value", "")}
          onChange={e => {
            const re = /^[0-9\b]+$/;
            if (re.test(e.target.value) || e.target.value !== "") {
              let data = this.state.fields;
              for (let i = 0; i < data.length; i++) {
                if (data[i].key === field.key) {
                  if (data[i].required && e.target.value === "") {
                    data[i].isValid = false;
                  } else {
                    data[i].isValid = true;
                  }
                  data[i].value = e.target.value;
                }
                this.numbervalue = data[i].isValid;
                this.numbervalue1 = data[i].value;
              }
              this.setState({
                fields: data
              });
            }
          }}
        />
      </div>
    );
  }

  rendertextAreaField(field) {
    if (field.required == false || field.required == undefined) {
      field.isValid = true;
    }
    const isformSubmitted = _.get(this, "state.formSubmitted", false);
    let opts = {};
    if (isformSubmitted) {
      opts["disabled"] = "disabled";
    }
    return (
      <div className="input-group mb-3" key={field.key}>
        <label
          className={
            _.get(this, "props.isRTLFlow", false) === true ? "font-arabic" : ""
          }
        >
          {_.get(field, "label", "")}
        </label>

        <textarea
          disabled={_.get(this, "props.disablebutton", true) ? "disabled" : ""}
          {...opts}
          maxLength="255"
          className={
            "rounded form-control email-field " +
            (_.get(this, "props.isRTLFlow", false)
              ? "rounded-right "
              : "rounded-left ")
          }
          value={_.get(field, "value", "")}
          onChange={e => {
            let data = this.state.fields;
            for (let i = 0; i < data.length; i++) {
              if (data[i].key === field.key) {
                if (data[i].required && e.target.value === "") {
                  data[i].isValid = false;
                } else {
                  data[i].isValid = true;
                }
                data[i].value = e.target.value;
              }
            }
            this.setState({
              fields: data
            });
          }}
        />
      </div>
    );
  }
  onBlur() {}
  renderEmailField(field) {
    let evalue = field.value;
    if (field.required == false || field.required == undefined) {
      field.isValid = true;
    }
    if (
      evalue.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      field.isValid = true;
    }
    const isformSubmitted = _.get(this, "state.formSubmitted", false);
    let opts = {};
    if (isformSubmitted) {
      opts["disabled"] = "disabled";
    }
    let x = document.getElementById("email");
    return (
      <div className="input-group mb-3" key={field.key}>
        <label
          className={
            _.get(this, "props.isRTLFlow", false) === true ? "font-arabic" : ""
          }
        >
          {_.get(field, "label", "")}
          {field.required ? <span className="required">*</span> : ""}

          <span className="text-right pull-right" style={{ color: "red" }}>
            {!this.emailvalue && this.emailvalue1 ? (
              <span>Please enter a your valid Email</span>
            ) : (
              ""
            )}
          </span>
        </label>

        <input
          disabled={_.get(this, "props.disablebutton", true) ? "disabled" : ""}
          {...opts}
          id="focus"
          type="text"
          maxLength="255"
          className={
            "rounded form-control email-field " +
            (_.get(this, "props.isRTLFlow", false)
              ? "rounded-right "
              : "rounded-left ")
          }
          value={_.get(field, "value", "")}
          onChange={e => {
            let data = this.state.fields;

            for (let i = 0; i < data.length; i++) {
              if (data[i].key === field.key) {
                data[i].value = e.target.value;
                let email = data[i].value;
                if (data[i].required && e.target.value === "") {
                  data[i].isValid = false;
                } else if (
                  !email.match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  )
                ) {
                  data[i].isValid = false;
                } else {
                  data[i].isValid = true;
                }
                if (data[i].isValid != undefined) {
                  this.emailvalue = data[i].isValid;
                }
                if (data[i].value != undefined) {
                  this.emailvalue1 = data[i].value;
                }
              }
            }

            this.setState({
              fields: data
            });
          }}
          autoComplete="off"
        />
      </div>
    );
  }

  renderDateField(field) {
    const isformSubmitted = _.get(this, "state.formSubmitted", false);
    const pastyear = field.pastyear;
    let opts = {};
    this.datevalue = field.isValid;
    this.datevalue1 = field.value;
    let temp = true;
    let realdateyearvaluedeafult = true;
    let realdateyearvalueyeardeafult = true;
    let datevalueyear = new Date().getFullYear();
    if (pastyear == true) {
      if (this.state.dateYearValue > datevalueyear) {
        field.isValid = false;
        this.temp = false;
      } else {
        this.temp = true;
      }
    }
    if (pastyear == false) {
      if (this.state.dateYearValue < datevalueyear) {
        field.isValid = false;
        this.temp = false;
      } else {
        this.temp = true;
      }
    }

    if (field.value != "Invalid Date") {
      field.isValid = true;
    }

    if (field.required == false || field.required == undefined) {
      field.isValid = true;
    } else if (
      this.state.dateDayValue > 31 ||
      this.state.dateMonthValue > 12 ||
      this.state.dateYearValue == ""
    ) {
      field.isValid = false;
    } else if (
      !this.realdateyearvaluedeafult &&
      this.realdateyearvalueyeardeafult &&
      this.datevalue1 &&
      this.state.dateYearValue
    ) {
      field.isValid = false;
    } else if (
      this.realdateyearvaluedeafult &&
      !this.realdateyearvalueyeardeafult &&
      this.datevalue1 &&
      this.state.dateYearValue
    ) {
      field.isValid = false;
    }

    if (isformSubmitted) {
      opts["disabled"] = "disabled";
    }
    return (
      <div className="input-group mb-3 datefield-sector">
        <label
          className={"requiredcolour"}
          className={
            _.get(this, "props.isRTLFlow", false) === true ? "font-arabic" : ""
          }
        >
          {_.get(field, "label", "")}
          {field.required ? <span className="required">*</span> : ""}

          <span className="text-right pull-right" style={{ color: "red" }}>
            {!this.realdateyearvaluedeafult &&
            this.realdateyearvalueyeardeafult &&
            this.datevalue1 &&
            this.state.dateYearValue ? (
              <span>Enter a Year below current</span>
            ) : (
              ""
            )}
            {(!this.datevalue &&
              this.datevalue1 &&
              !this.state.dateYearValue) ||
            ((this.state.dateDayValue > 31 || this.state.dateMonthValue > 12) &&
              this.temp == true) ? (
              <span>Please enter a your valid Date</span>
            ) : (
              ""
            )}

            {this.realdateyearvaluedeafult &&
            !this.realdateyearvalueyeardeafult &&
            this.datevalue1 &&
            this.state.dateYearValue ? (
              <span>Enter a Year above current</span>
            ) : (
              ""
            )}
          </span>
        </label>
        {_.get(this, "props.isRTLFlow", false) === false && (
          <React.Fragment>
            <div className="col-2 pl-1 pr-1">
              <input
                disabled={
                  _.get(this, "props.disablebutton", true) ? "disabled" : ""
                }
                type="text"
                className={"rounded form-control text-center "}
                placeholder="09"
                minLength="1"
                maxLength="2"
                autoComplete="off"
                {...opts}
                value={_.get(this, "state.dateDayValue", "")}
                onChange={e => {
                  const re = /^[0-9\b]+$/;

                  if (re.test(e.target.value) || e.target.value === "") {
                    if (e.target.value.length === 2) {
                      this.dateMonthValue.focus();
                    }
                    this.setState(
                      {
                        dateDayValue: e.target.value
                      },
                      () => {
                        const date = new Date(
                          parseInt(_.get(this, "state.dateYearValue")),
                          parseInt(_.get(this, "state.dateMonthValue")) - 1,
                          parseInt(_.get(this, "state.dateDayValue"))
                        );

                        let data = this.state.fields;
                        for (let i = 0; i < data.length; i++) {
                          if (data[i].key === field.key) {
                            data[i].value = date;
                            data[i].isValid =
                              date.toString() === "Invalid Date" ? false : true;
                          }
                        }
                        this.setState({
                          fields: data
                        });

                        // this.dateMonthValue.focus();
                      }
                    );
                  }
                }}
                ref={el => {
                  this.dateDayValue = el;
                }}
              />
              <label className="form-text float-left info-label">Day</label>
            </div>
            <div>
              <img src={slashIcon} alt="" />{" "}
            </div>
            <div className="col-2 pl-1 pr-1 ">
              <input
                disabled={
                  _.get(this, "props.disablebutton", true) ? "disabled" : ""
                }
                type="text"
                className={"rounded form-control text-center "}
                placeholder="09"
                name="txthour"
                id="txthour"
                minLength="1"
                maxlength="12"
                {...opts}
                value={_.get(this, "state.dateMonthValue", "")}
                onChange={e => {
                  const re = /^[0-9\b]+$/;
                  if (re.test(e.target.value) || e.target.value === "") {
                    if (e.target.value.length === 2) {
                      this.dateYearValue.focus();
                    }
                    this.setState(
                      {
                        dateMonthValue: e.target.value
                      },
                      () => {
                        let data = this.state.fields;

                        const date = new Date(
                          parseInt(_.get(this, "state.dateYearValue")),
                          parseInt(_.get(this, "state.dateMonthValue")) - 1,
                          parseInt(_.get(this, "state.dateDayValue"))
                        );

                        for (let i = 0; i < data.length; i++) {
                          if (data[i].key === field.key) {
                            data[i].value = date;
                            data[i].isValid =
                              date.toString() === "Invalid Date" ? false : true;
                          }
                        }
                        this.setState({
                          fields: data
                        });
                      }
                    );
                  }
                }}
                ref={el => {
                  this.dateMonthValue = el;
                }}
              />
              <label className="form-text float-left info-label">Month</label>
            </div>
            <div>
              <img src={slashIcon} alt="" />{" "}
            </div>
            <div className="pl-1 pr-1 year">
              <input
                disabled={
                  _.get(this, "props.disablebutton", true) ? "disabled" : ""
                }
                type="text"
                placeholder="2019"
                className={"rounded form-control text-center "}
                minLength="4"
                maxLength="4"
                {...opts}
                value={_.get(this, "state.dateYearValue", "")}
                onChange={e => {
                  const re = /^[0-9\b]+$/;

                  if (re.test(e.target.value) || e.target.value === "") {
                    let realdateyearvalue = e.target.value;

                    let datevalueyear = new Date().getFullYear();
                    this.setState(
                      {
                        dateYearValue: e.target.value
                      },
                      () => {
                        let data = this.state.fields;
                        const date = new Date(
                          parseInt(_.get(this, "state.dateYearValue")),
                          parseInt(_.get(this, "state.dateMonthValue")) - 1,
                          parseInt(_.get(this, "state.dateDayValue"))
                        );

                        for (let i = 0; i < data.length; i++) {
                          if (data[i].key === field.key) {
                            data[i].value = date;
                            if (pastyear == true) {
                              if (realdateyearvalue > datevalueyear) {
                                data[i].isValid = false;
                                this.realdateyearvaluedeafult = false;
                                this.realdateyearvalueyeardeafult = true;
                              } else {
                                this.realdateyearvaluedeafult = false;
                                this.realdateyearvalueyeardeafult = false;
                              }
                            } else if (pastyear == false) {
                              if (pastyear == false) {
                                if (realdateyearvalue >= datevalueyear) {
                                  this.realdateyearvaluedeafult = false;
                                } else {
                                  data[i].isValid = false;
                                  this.realdateyearvalueyeardeafult = false;
                                  this.realdateyearvaluedeafult = true;
                                }
                              }
                            } else {
                              if (realdateyearvalue < datevalueyear) {
                                data[i].isValid = false;
                                this.realdateyearvaluedeafult = false;
                              } else {
                                this.realdateyearvaluedeafult = true;
                                data[i].isValid =
                                  date.toString() === "Invalid Date"
                                    ? false
                                    : true;
                              }
                            }

                            // data[i].isValid =
                            //   date.toString() === "Invalid Date" ? false : true;
                          }
                        }
                        this.setState({
                          fields: data
                        });
                      }
                    );
                  }
                }}
                ref={el => {
                  this.dateYearValue = el;
                }}
              />
              <label className="form-text float-left info-label">Year</label>
            </div>
          </React.Fragment>
        )}
        {_.get(this, "props.isRTLFlow", false) === true && (
          <React.Fragment>
            <div className="col-2 pl-1 pr-1">
              <input
                disabled={
                  _.get(this, "props.disablebutton", true) ? "disabled" : ""
                }
                type="text"
                className=" rounded form-control text-center "
                placeholder="09"
                minLength="1"
                maxLength="2"
                {...opts}
                value={_.get(this, "state.dateDayValue", "")}
                onChange={e => {
                  const re = /^[0-9\b]+$/;
                  if (re.test(e.target.value) || e.target.value === "") {
                    this.setState(
                      {
                        dateDayValue: e.target.value
                      },
                      () => {
                        let data = this.state.fields;
                        const date = new Date(
                          parseInt(_.get(this, "state.dateYearValue")),
                          parseInt(_.get(this, "state.dateMonthValue")) - 1,
                          parseInt(_.get(this, "state.dateDayValue"))
                        );

                        for (let i = 0; i < data.length; i++) {
                          if (data[i].key === field.key) {
                            data[i].value = date;
                            data[i].isValid =
                              date.toString() === "Invalid Date" ? false : true;
                          }
                        }
                        this.setState({
                          fields: data
                        });
                      }
                    );
                  }
                }}
                ref={el => {
                  this.dateDayValue = el;
                }}
              />
              <label className="form-text float-left info-label font-arabic">
                اليوم
              </label>
            </div>
            <div>
              <img src={slashIcon} alt="" />{" "}
            </div>
            <div className="col-2 pl-1 pr-1">
              <input
                disabled={
                  _.get(this, "props.disablebutton", true) ? "disabled" : ""
                }
                type="text"
                className={"rounded form-control text-center "}
                placeholder="09"
                minLength="1"
                maxLength="2"
                {...opts}
                value={_.get(this, "state.dateMonthValue", "")}
                onChange={e => {
                  const re = /^[0-9\b]+$/;
                  if (re.test(e.target.value) || e.target.value === "") {
                    this.setState(
                      {
                        dateMonthValue: e.target.value
                      },
                      () => {
                        let data = this.state.fields;
                        const date = new Date(
                          parseInt(_.get(this, "state.dateYearValue")),
                          parseInt(_.get(this, "state.dateMonthValue")) - 1,
                          parseInt(_.get(this, "state.dateDayValue"))
                        );

                        for (let i = 0; i < data.length; i++) {
                          if (data[i].key === field.key) {
                            data[i].value = date;
                            data[i].isValid =
                              date.toString() === "Invalid Date" ? false : true;
                          }
                        }
                        this.setState({
                          fields: data
                        });
                      }
                    );
                  }
                }}
                ref={el => {
                  this.dateMonthValue = el;
                }}
              />
              <label className="form-text float-left info-label font-arabic">
                الشهر
              </label>
            </div>
            <div>
              <img src={slashIcon} alt="" />{" "}
            </div>
            <div className="pl-1 pr-1 year">
              <input
                disabled={
                  _.get(this, "props.disablebutton", true) ? "disabled" : ""
                }
                type="text"
                placeholder="2019"
                className={"rounded form-control text-center "}
                minLength="4"
                maxLength="4"
                {...opts}
                value={_.get(this, "state.dateYearValue", "")}
                onChange={e => {
                  const re = /^[0-9\b]+$/;
                  if (re.test(e.target.value) || e.target.value === "") {
                    this.setState(
                      {
                        dateYearValue: e.target.value
                      },
                      () => {
                        let data = this.state.fields;
                        const date = new Date(
                          parseInt(_.get(this, "state.dateYearValue")),
                          parseInt(_.get(this, "state.dateMonthValue")) - 1,
                          parseInt(_.get(this, "state.dateDayValue"))
                        );

                        for (let i = 0; i < data.length; i++) {
                          if (data[i].key === field.key) {
                            data[i].value = date;
                            data[i].isValid =
                              date.toString() === "Invalid Date" ? false : true;
                          }
                        }
                        this.setState({
                          fields: data
                        });
                      }
                    );
                  }
                }}
                ref={el => {
                  this.dateYearValue = el;
                }}
              />
              <label className="form-text float-left info-label font-arabic">
                السنة
              </label>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }

  renderDateFieldNew(field) {
    const isformSubmitted = _.get(this, "state.formSubmitted", false);
    let opts = {};
    const pastyear = field.pastyear;
    this.datevalue1 = field.value;
    const { maxDates } = this.state;

    if (isformSubmitted) {
      opts["disabled"] = "disabled";
    }
    return (
      <div className="input-group mb-3 datefield-sector" key={field.key}>
        <Calendar
          calendarType={"Arabic"}
          minDate={pastyear ? null : new Date()}
          maxDate={pastyear ? new Date() : maxDates}
          onChange={date => this.handleDate(date, field)}
        />
      </div>
    );
  }

  handleDate = (date, field) => {
    let data = this.state.fields;
    for (let i = 0; i < data.length; i++) {
      if (data[i].key === field.key) {
        data[i].value = date;
        data[i].isValid = date.toString() === "Invalid Date" ? false : true;
      }
    }
    this.setState({
      fields: data
    });
  };

  renderPhoneField(field) {
    let phonevalue = field.value;
    if (
      phonevalue.match(
        /^(?:\+971|00971|0|971)(?!2)((?:2|3|4|5|6|7|9|50|51|52|55|56)[0-9]{8,})/m
      )
    ) {
      field.isValid = true;
    }

    if (field.required == false || field.required == undefined) {
      field.isValid = true;
    }
    const isformSubmitted = _.get(this, "state.formSubmitted", false);
    let opts = {};
    if (isformSubmitted) {
      opts["disabled"] = "disabled";
    }
    this.phonevalue = field.isValid;
    this.phonevalue1 = field.value;

    return (
      <div className="input-group mb-3" key={field.key}>
        <label
          className={_.get(this, "props.isRTLFlow", false) ? "font-arabic" : ""}
        >
          {_.get(field, "label", "")}
          {field.required ? <span className="required">*</span> : ""}

          <span className="text-right pull-right" style={{ color: "red" }}>
            {!this.phonevalue && this.phonevalue1 ? (
              <span>Please enter a your valid Mobile Number</span>
            ) : (
              ""
            )}
          </span>
        </label>
        {_.get(this, "props.isRTLFlow", false) && (
          <React.Fragment>
            <input
              disabled={
                _.get(this, "props.disablebutton", true) ? "disabled" : ""
              }
              required
              type="text"
              placeholder="XXXXX XXX"
              className="col-8 rounded-right form-control "
              maxLength="9"
              {...opts}
              autoComplete="off"
              value={_.get(field, "value", "")}
              onChange={e => {
                const re = /^[0-9\b]+$/;

                if (!(re.test(e.target.value) || e.target.value !== "")) {
                  return false;
                }
                let data = this.state.fields;

                for (let i = 0; i < data.length; i++) {
                  if (data[i].key === field.key) {
                    data[i].value = e.target.value;
                    let phone = "971" + data[i].value;
                    if (!(re.test(e.target.value) || e.target.value !== "")) {
                      data[i].isValid = false;
                    } else if (
                      !phone.match(
                        /^(?:\+971|00971|0|971)(?!2)((?:2|3|4|5|6|7|9|50|51|52|55|56)[0-9]{8,})/m
                      )
                    ) {
                      data[i].isValid = false;
                    } else {
                      data[i].isValid = true;
                    }
                  }
                }
                this.setState({
                  fields: data
                });
              }}
            />
            <input
              disabled={
                _.get(this, "props.disablebutton", true) ? "disabled" : ""
              }
              type="text"
              placeholder="+971"
              className="col-3 mr-3 rounded-left form-control phone-control"
              disabled
              onChange={e => this.handleInputValue(e, "phonecode")}
              value={_.get(this, "state.phoneCodeValue", "971")}
            />
          </React.Fragment>
        )}
        {_.get(this, "props.isRTLFlow", false) === false && (
          <React.Fragment>
            <input
              disabled={
                _.get(this, "props.disablebutton", true) ? "disabled" : ""
              }
              type="text"
              placeholder="+971"
              className="col-3 mr-3 rounded form-control phone-control"
              disabled
              onChange={e => this.handleInputValue(e, "phonecode")}
              value={_.get(this, "state.phoneCodeValue", "+971")}
            />
            <input
              disabled={
                _.get(this, "props.disablebutton", true) ? "disabled" : ""
              }
              type="text"
              placeholder="XXXXX XXX"
              className="col-8 rounded form-control "
              maxLength="9"
              {...opts}
              autoComplete="off"
              value={_.get(field, "value", "")}
              onChange={e => {
                const re = /^[0-9\b]+$/;
                if (!re.test(e.target.value) && e.target.value !== "") {
                  return false;
                }
                let data = this.state.fields;

                for (let i = 0; i < data.length; i++) {
                  if (data[i].key === field.key) {
                    data[i].value = e.target.value;
                    let phone = "971" + data[i].value;
                    if (data[i].required && e.target.value === "") {
                      data[i].isValid = false;
                    } else if (
                      !phone.match(
                        /^(?:\+971|00971|0|971)(?!2)((?:2|3|4|5|6|7|9|50|51|52|55|56)[0-9]{8,})/m
                      )
                    ) {
                      data[i].isValid = false;
                    } else {
                      data[i].isValid = true;
                    }
                  }
                }

                this.setState({
                  fields: data
                });
              }}
            />
          </React.Fragment>
        )}
      </div>
    );
  }

  renderPasswordField(field) {
    if (field.required == false || field.required == undefined) {
      field.isValid = true;
    }
    if (field.value != "") {
      field.isValid = true;
    }
    const isformSubmitted = _.get(this, "state.formSubmitted", false);
    let opts = {};
    if (isformSubmitted) {
      opts["disabled"] = "disabled";
    }
    return (
      <div className="input-group mb-3" key={field.key}>
        <label
          className={
            _.get(this, "props.isRTLFlow", false) === true ? "font-arabic" : ""
          }
        >
          {_.get(field, "label", "")}
          {field.required ? <span className="required">*</span> : ""}
        </label>
        <input
          disabled={_.get(this, "props.disablebutton", true) ? "disabled" : ""}
          type="password"
          {...opts}
          maxLength="255"
          className={
            "rounded form-control email-field " +
            (_.get(this, "props.isRTLFlow", false)
              ? "rounded-right "
              : "rounded-left ")
          }
          value={_.get(field, "value", "")}
          onChange={e => {
            let data = this.state.fields;
            for (let i = 0; i < data.length; i++) {
              if (data[i].key === field.key) {
                if (data[i].required && e.target.value === "") {
                  data[i].isValid = false;
                } else {
                  data[i].isValid = true;
                }
                data[i].value = e.target.value;
              }
              if (data[i].isValid != undefined) {
                this.passwordvalue = data[i].isValid;
              }

              if (data[i].value != undefined) {
                this.passwordvalue1 = data[i].value;
              }
            }
            this.setState({
              fields: data
            });
          }}
        />
      </div>
    );
  }

  renderOtpField(field) {
    if (field.required == false || field.required == undefined) {
      field.isValid = true;
    }
    const isformSubmitted = _.get(this, "state.formSubmitted", false);

    let opts = {};
    if (isformSubmitted) {
      opts["disabled"] = "disabled";
    }
    return (
      <div className="input-group mb-3" key={field.key}>
        <label
          className={
            _.get(this, "props.isRTLFlow", false) === true ? "font-arabic" : ""
          }
        >
          {_.get(field, "label", "")}
          {field.required ? <span className="required">*</span> : ""}
        </label>
        <div className="form-row">
          {_.map(this.state.otp, (otp, index) => {
            return (
              <input
                disabled={
                  _.get(this, "props.disablebutton", true) ? "disabled" : ""
                }
                type={field.hideChar ? "password" : "text"}
                key={index}
                maxLength="1"
                {...opts}
                className={
                  "form-control col mt-3 m-1  fill-otp text-center " +
                  (field.hideChar ? "font-30" : "")
                }
                value={otp}
                onChange={e => {
                  let otp = _.get(this, "state.otp", []);
                  otp[index] = e.target.value;

                  this.setState({
                    otp: otp
                  });
                }}
              />
            );
          })}
        </div>
      </div>
    );
  }

  renderSubmitButton(field) {
    const isformSubmitted = _.get(this, "state.formSubmitted", false);
    let opts = {};
    if (isformSubmitted) {
      opts["disabled"] = "disabled";
    }
    return (
      <div className="btn-main-block main-left-m" key={field.key}>
        <div className="btn-wrapper">
          <button
            disabled={
              _.get(this, "props.disablebutton", true) ? "disabled" : ""
            }
            {...opts}
            type="button"
            className={
              "btn btn-block btn-text-white " +
              (this.isValidForm() ? " btn-blue " : "") +
              (_.get(this, "props.isRTLFlow", false) === true
                ? "font-arabic"
                : "")
            }
            onClick={() => {
              this.setState({
                submit_val: _.get(field, "data", "")
              });
              this.sendForm();
            }}
          >
            {_.get(field, "label", "")}
          </button>
        </div>
      </div>
    );
  }
  renderCancelButton(field) {
    const isformSubmitted = _.get(this, "state.formSubmitted", false);
    let opts = {};
    if (isformSubmitted) {
      opts["disabled"] = "disabled";
    }
    return (
      <div className="btn-main-block main-left-m" key={field.key}>
        <div className="btn-wrapper">
          <button
            disabled={
              _.get(this, "props.disablebutton", true) ? "disabled" : ""
            }
            {...opts}
            type="button"
            className={
              "btn btn-block btn-text-white " +
              " btn-blue " +
              (_.get(this, "props.isRTLFlow", false) === true
                ? "font-arabic"
                : "")
            }
            onClick={() => this.cancelForm()}
          >
            {_.get(field, "label", "")}
          </button>
        </div>
      </div>
    );
  }

  renderSelectField(field) {
    if (field.required == false) {
      field.isValid = true;
    }
    const isformSubmitted = _.get(this, "state.formSubmitted", false);
    let opts = {};
    if (isformSubmitted) {
      opts["disabled"] = "disabled";
    }
    const options = _.get(field, "options", []);
    return (
      <div className="input-group mb-3" key={field.key}>
        <label
          className={
            _.get(this, "props.isRTLFlow", false) === true ? "font-arabic" : ""
          }
        >
          {_.get(field, "label", "")}
          {field.required ? <span className="required">*</span> : ""}
        </label>
        <select
          disabled={_.get(this, "props.disablebutton", true) ? "disabled" : ""}
          {...opts}
          className={
            "rounded form-control email-field " +
            (_.get(this, "props.isRTLFlow", false)
              ? "rounded-right "
              : "rounded-left ")
          }
          value={_.get(field, "value", "")}
          onChange={e => {
            let data = this.state.fields;
            for (let i = 0; i < data.length; i++) {
              if (data[i].key === field.key) {
                if (data[i].required && e.target.value === "") {
                  data[i].isValid = false;
                } else {
                  data[i].isValid = true;
                }
                data[i].value = e.target.value;
              }
            }
            this.setState({
              fields: data
            });
          }}
        >
          <option value="">Please Select</option>
          {_.map(options, option => {
            return <option value={option.value}>{option.label}</option>;
          })}
        </select>
      </div>
    );
  }

  renderRadioBoxField(field) {
    if (field.required == false) {
      field.isValid = true;
    }
    const isformSubmitted = _.get(this, "state.formSubmitted", false);
    let opts = {};
    if (isformSubmitted) {
      opts["disabled"] = "disabled";
    }

    const options = _.get(field, "data", []);
    return (
      <div className="input-group mb-3" key={field.key}>
        <label
          className={
            _.get(this, "props.isRTLFlow", false) === true ? "font-arabic" : ""
          }
        >
          {_.get(field, "label", "")}
          {field.required ? <span className="required">*</span> : ""}
        </label>
        <div>
          {_.map(options, option => {
            return (
              <label className="radio-label" for="radio">
                <input
                  disabled={
                    _.get(this, "props.disablebutton", true) ? "disabled" : ""
                  }
                  className="radio-input"
                  type="radio"
                  id={option.title}
                  name="radiobox"
                  {...opts}
                  value={option.payload}
                  //  value = {_.get(field, "value", "")}
                  //  value = {_.get(field, "value", "")}
                  onChange={evt => {
                    // this.state.fields = e.target.value;
                    let data = this.state.fields;
                    for (let i = 0; i < data.length; i++) {
                      if (data[i].key === field.key) {
                        if (data[i].required && evt.target.value === "") {
                          data[i].isValid = false;
                        } else {
                          data[i].isValid = true;
                        }
                        //data[i].value = evt.target.value;
                      }
                    }
                    this.setState({
                      radiovalue: evt.target.value
                    });
                  }}
                />
                {option.title}
              </label>
            );
          })}
        </div>
      </div>
    );
  }

  renderTimeField(field) {
    if (field.required == false) {
      field.isValid = true;
    }
    const isformSubmitted = _.get(this, "state.formSubmitted", false);
    let hourarroption = new Array(
      "00",
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12"
    );
    let minutearroption = new Array(
      "00",
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31",
      "32",
      "33",
      "34",
      "35",
      "36",
      "37",
      "38",
      "39",
      "40",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "50",
      "51",
      "52",
      "53",
      "54",
      "55",
      "56",
      "57",
      "58",
      "59"
    );
    let secondarroption = new Array("AM", "PM");

    let opts = {};
    if (isformSubmitted) {
      opts["disabled"] = "disabled";
    }
    return (
      <div>
        <label
          className={
            _.get(this, "props.isRTLFlow", false) === true ? "font-arabic" : ""
          }
        >
          {_.get(field, "label", "")}
          {field.required ? <span className="required">*</span> : ""}
        </label>

        <div className="Column">
          <select
            disabled={
              _.get(this, "props.disablebutton", true) ? "disabled" : ""
            }
            {...opts}
            name=""
            className={
              "rounded form-control email-field " +
              (_.get(this, "props.isRTLFlow", false)
                ? "rounded-right "
                : "rounded-left ")
            }
            defaultValue={""}
            onChange={evt => {
              let data = this.state.fields;
              for (let i = 0; i < data.length; i++) {
                if (data[i].key === field.key) {
                  if (
                    this.state.hourselect === "" &&
                    this.state.minuteselect === ""
                  ) {
                    data[i].isValid = false;
                  } else {
                    data[i].isValid = true;
                  }
                  let hourfields = evt.target.value;
                  let minutefields = _.get(this, "state.minuteselect", []);
                  let secondfields = _.get(this, "state.secondselect", []);
                  let allarrayoption =
                    hourfields + "-" + minutefields + "-" + secondfields;
                  data[i].value = allarrayoption;
                }
              }
              this.setState({
                hourselect: evt.target.value
              });
            }}
          >
            value = {_.get(field, "value", "")}
            <option value="" disabled hidden>
              Hrs
            </option>
            {_.map(hourarroption, (hour, i) => {
              return (
                <option key={i} value={hourarroption[i]}>
                  {i}{" "}
                </option>
              );
            })}
            ) }
          </select>
        </div>
        <div className="Column">
          <select
            {...opts}
            className={
              "rounded form-control email-field " +
              (_.get(this, "props.isRTLFlow", false)
                ? "rounded-right "
                : "rounded-left ")
            }
            defaultValue={""}
            onChange={evt => {
              let data = this.state.fields;
              for (let i = 0; i < data.length; i++) {
                if (data[i].key === field.key) {
                  if (
                    this.state.hourselect === "" ||
                    this.state.secondselect === ""
                  ) {
                    data[i].isValid = false;
                  } else {
                    data[i].isValid = true;
                  }
                  let hourfields = _.get(this, "state.hourselect", []);
                  let minutefields = evt.target.value;
                  let secondfields = _.get(this, "state.secondselect", []);
                  let allarrayoption =
                    hourfields + "-" + minutefields + "-" + secondfields;
                  data[i].value = allarrayoption;
                }
              }
              this.setState({
                minuteselect: evt.target.value
              });
            }}
          >
            value = {_.get(field, "value", "")}
            <option value="" disabled hidden>
              Min
            </option>
            {_.map(minutearroption, (minute, i) => {
              return (
                <option key={i} value={minutearroption[i]}>
                  {i}
                </option>
              );
            })}
            ) }
          </select>
        </div>
        <div className="Column">
          <select
            disabled={
              _.get(this, "props.disablebutton", true) ? "disabled" : ""
            }
            {...opts}
            className={
              "rounded form-control email-field" +
              (_.get(this, "props.isRTLFlow", false)
                ? "rounded-right "
                : "rounded-left ")
            }
            defaultValue={""}
            onChange={evt => {
              let data = this.state.fields;
              for (let i = 0; i < data.length; i++) {
                if (data[i].key === field.key) {
                  if (
                    this.state.hourselect === "" ||
                    this.state.minuteselect === ""
                  ) {
                    data[i].isValid = false;
                  } else {
                    data[i].isValid = true;
                  }

                  let hourfields = _.get(this, "state.hourselect", []);
                  let minutefields = _.get(this, "state.minuteselect", []);
                  let secondfields = evt.target.value;
                  let allarrayoption =
                    hourfields + "-" + minutefields + "-" + secondfields;
                  data[i].value = allarrayoption;
                }
              }
              this.setState({
                secondselect: evt.target.value
              });
            }}
          >
            value = {_.get(field, "value", "")}
            <option value="AM" selected="selected" hidden>
              AM
            </option>
            {_.map(secondarroption, (second, i) => {
              return (
                <option key={i} value={secondarroption[i]}>
                  {secondarroption[i]}
                </option>
              );
            })}
            ) }
          </select>
        </div>
      </div>
    );
  }
  renderCheckBoxField(field) {
    if (field.required == false) {
      field.isValid = true;
    }
    const isformSubmitted = _.get(this, "state.formSubmitted", false);
    let opts = {};
    if (isformSubmitted) {
      opts["disabled"] = "disabled";
    }

    const options = _.get(field, "data", []);
    return (
      <div>
        <label
          className={
            _.get(this, "props.isRTLFlow", false) === true ? "font-arabic" : ""
          }
        >
          {_.get(field, "label", "")}
          {field.required ? <span className="required">*</span> : ""}
        </label>
        <div class="multiselect">
          <div
            class="selectBox"
            onClick={() => {
              let checkboxes = document.getElementById("checkboxes");
              if (!this.state.expanded) {
                checkboxes.style.display = "block";
                this.setState({
                  expanded: true
                });
              } else {
                checkboxes.style.display = "none";
                this.setState({
                  expanded: false
                });
              }
            }}
          >
            <select className="select-opt">
              <option>Select an option</option>
            </select>
            <div class="overSelect" />
          </div>
          <div id="checkboxes">
            {_.map(options, option => {
              return (
                <label for={option.title}>
                  <input
                    className="input-opt"
                    type="checkbox"
                    id={option.title}
                    {...opts}
                    name="check"
                    value={option.payload}
                    onClick={evt => {
                      if (evt.target.checked === false) {
                        let tempvalue = evt.target.value;
                        let index = this.state.checkvalue.indexOf(tempvalue);
                        if (index > -1) {
                          this.state.checkvalue.splice(index, 1);
                        }
                      }
                      let data = this.state.fields;
                      for (let i = 0; i < data.length; i++) {
                        if (data[i].key === field.key) {
                          if (data[i].required && evt.target.value === "") {
                            data[i].isValid = false;
                          } else {
                            data[i].isValid = true;
                          }
                        }
                      }
                      if (evt.target.checked !== false) {
                        this.setState({
                          checkvalue: this.state.checkvalue.concat(
                            evt.target.value
                          )
                        });
                      }
                    }}
                  />
                  <p>{option.title}</p>
                </label>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
  renderUploadField(field) {
    if (field.required == false) {
      field.isValid = true;
    }
    const isformSubmitted = _.get(this, "state.formSubmitted", false);
    let multiple = _.get(field, "data", []);
    let maxfiles = multiple[0].maxfiles;
    let uploadurl = _.get(field, "uploadurl", []);
    let opts = {};
    if (isformSubmitted) {
      opts["disabled"] = "disabled";
    }
    if (!isformSubmitted && multiple[0].allowmultiple == true) {
      opts["allowMultiple"] = "allowMultiple";
    }

    return (
      <div key={field.key}>
        <label
          className={
            _.get(this, "props.isRTLFlow", false) === true ? "font-arabic" : ""
          }
        >
          {_.get(field, "label", "")}
        </label>
        {/* <FilePond
          id="file"
          type="file"
          name="images"
          {...opts}
          server={uploadurl}
          onupdatefiles={evt => {
            let data = this.state.fields;
            data[0].isValid = true;
          }}
        /> */}
        {/* <input
              id="file"
              type="file"
              name="myFile" multiple
              {...opts}
              onChange={(evt) => {
                let data = this.state.fields;
                    for(let i=0; i<data.length; i++) {
                      if(data[i].key === field.key) {
                        if(evt.target.files[0] === "") {
                          data[i].isValid = false;
                        } else {
                          data[i].isValid = true;
                        }
                      }
                    }
                this.setState({
                  selectedFile: evt.target.files[0],
                  loaded: 0,
                })
              } 
            }/> */}
      </div>
    );
  }

  isValidForm() {
    let fields = _.get(this, "state.fields", []);
    let result = false;
    let validlenght = 0;
    for (let i = 0; i < fields.length; i++) {
      if (fields[i].isValid == false) {
        validlenght++;
      }
    }

    if (validlenght == 0) {
      for (let i = 0; i < fields.length; i++) {
        if (fields[i].isValid != false && fields[i].isValid != undefined) {
          if (_.has(fields[i], "value")) {
            result = false;
            if (fields[i].type === "otp") {
              if (
                _.map(_.get(this, "state.otp", [])).join("").length ===
                fields[i].fields
              ) {
                result = true;
              }
            } else if (fields[i].isValid) {
              result = true;
            }

            // if(fields[i].required && fields[i].value !== '' && _.has(fields[i],"value")) {
            //   result = true;
            // }
          }
        }
      }
    }
    return result;
  }

  sendForm() {
    if (!this.isValidForm()) {
      return false;
    }

    let fields = _.get(this, "state.fields", []);
    let radiovalue = _.get(this, "state.radiovalue", []);
    let checkvalue = _.get(this, "state.checkvalue", []);
    let data = {};
    for (let i = 0; i < fields.length; i++) {
      if (_.has(fields[i], "value")) {
        if (fields[i].data === "phone") {
          _.set(data, fields[i].data, "971" + fields[i].value);
        } else if (fields[i].type === "radiobox") {
          _.set(data, fields[i].type, radiovalue);
        } else if (fields[i].type === "checkbox") {
          _.set(data, fields[i].type, checkvalue);
        } else if (fields[i].data === "otp") {
          _.set(
            data,
            fields[i].data,
            _.map(_.get(this, "state.otp", [])).join("")
          );
        } else {
          _.set(data, fields[i].data, fields[i].value);
        }
      }
    }
    this.setState(
      {
        formSubmitted: true
      },
      () => {
        // this.props.userFormReply(
        //   JSON.stringify({
        //     data
        //   })
        // )
        this.props.userFormReply(
          "/" + _.get(this, "state.submit_val", "") + JSON.stringify(data)
        );
      }
    );
  }
  cancelForm() {
    let Cancel = "Cancel";

    this.setState({}, () => {
      this.props.userFormReply(
        JSON.stringify({
          Cancel
        })
      );
    });
  }

  render() {
    const fields = _.get(this, "state.fields", []);
    return (
      <div className="form-main">
        <form className="pl-4 pr-4 pt-0">
          {_.map(fields, field => {
            if (field.type === "text") {
              return this.renderTextField(field);
            }
            if (field.type === "number") {
              return this.renderNumericField(field);
            }
            if (field.type === "textarea") {
              return this.rendertextAreaField(field);
            }
            if (field.type === "email") {
              return this.renderEmailField(field);
            }
            if (field.type === "date") {
              return this.renderDateFieldNew(field);
            }
            if (field.type === "phone") {
              return this.renderPhoneField(field);
            }
            if (field.type === "select") {
              return this.renderSelectField(field);
            }
            if (field.type === "time") {
              return this.renderTimeField(field);
            }
            if (field.type === "checkbox") {
              return this.renderCheckBoxField(field);
            }
            if (field.type === "radiobox") {
              return this.renderRadioBoxField(field);
            }
            if (field.type === "password") {
              return this.renderPasswordField(field);
            }
            if (field.type === "otp") {
              return this.renderOtpField(field);
            }
            // if (field.type === "upload") {
            //   return this.renderUploadField(field);
            // }
            if (field.type === "submit") {
              return this.renderSubmitButton(field);
            }
            if (field.type === "cancel") {
              return this.renderCancelButton(field);
            }
          })}
        </form>
      </div>
    );
  }
}
