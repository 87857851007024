import React from "react";
// images
import leftlogo from "./../../public/images/left_logo.svg";
import rightlogo from "./../../public/images/right_logo.svg";
import icedit from "./../../public/images/edit.svg";
import iceditFill from "./../../public/images/edit_fills.svg";

class PaymentContract extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="form-pay-main">
        <div className="form-header">
          <div className="form-logo-left">
            <img src={leftlogo} alt="" />
          </div>
          <div className="form-logo-right">
            <img src={rightlogo} alt="" />
          </div>
        </div>
        <div className="form-payment text-left">
          <div className="secure-text">
            <span className="float-left">
              <img src={icedit} alt="" className="icedit" />
            </span>
            <h1 className="font-big">Edit Tenancy Contract</h1>
          </div>
          <div className="payment-cards mt-2">
            <div className="form-group">
              <label>Emirates Identity Number</label>
              <label className="form-value">BBG7767799762</label>
            </div>
            <div className="form-group">
              <label>Tenancy Contract Number</label>
              <label className="form-value">BBG7767799762</label>
            </div>

            <div className="input-group mb-3">
              <label>Rental Amount (AED)</label>
              <input
                type="email"
                className="form-control form-input-text border-right-0 rounded-left"
                placeholder="13,000"
              />
              <div className="input-group-append">
                <span className="input-group-text form-input-ic">
                  <img src={iceditFill} alt="" />
                </span>
              </div>
            </div>

            <div className="input-group mb-3">
              <label>No of Installments</label>
              <div className="col">
                <div className="row">
                  <select className="form-control form-dd">
                    <option value="12">12</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="input-group mb-3">
              <label>Electricity Bill paid by</label>
              <div className="col">
                <div className="row">
                  <select className="form-control form-dd">
                    <option value="Tenant">Tenant</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="input-group mb-3">
              <label>Contract Duration</label>
              <div className="col">
                <div className="row">
                  <select className="form-control form-dd">
                    <option value="2 years">2 years</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="input-group mb-3">
              <label>Security Deposit (AED)</label>
              <div className="col">
                <div className="row">
                  <select className="form-control form-dd">
                    <option value="25000">25,000</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="input-group mb-3">
              <label>Installments Payment Method</label>
              <div className="col">
                <div className="row">
                  <select className="form-control form-dd">
                    <option value="cheque">Cheque</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="form-group mt-4">
              <button className="btn btn-block btn-complete-transaction">
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PaymentContract;