import React from "react";
import _ from "lodash";
import welcometxt from "./../../public/images/text_ar.png";
import Municipalitytxt from "./../../public/images/text_ar_municiple.png";

import './initial-screen.scss';
// config
import { ENVIORMENT } from "./../../env";

import LayoutOne from './LayoutOne';
import LayoutTwo from "./LayoutTwo";

class InitialScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.startConversation = this.startConversation.bind(this);
    this.endConversation = this.endConversation.bind(this);
  }
  
  componentDidMount() {}
  componentDidUpdate() {}

  startConversation(selectedAvatar, displayAvatar, lang = "English") {
    this.props.loadConversationScreen(selectedAvatar, displayAvatar);
    this.props.toggleRTL(lang);
  }
  
  endConversation() {
    this.props.endConversation();
  }

  render() {
    if ( (_.get(ENVIORMENT, "theme", "")) === "medcare") {
      return <LayoutTwo startConversation={this.startConversation}  endConversation={this.endConversation}  />
    } else {
      return <LayoutOne welcometxt={welcometxt}
      Municipalitytxt={Municipalitytxt} startConversation={this.startConversation}  endConversation={this.endConversation}  />
    }
  }
}
export default InitialScreen;
