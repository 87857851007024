import React from "react";

import leftlogo from "./../../public/images/left_logo.svg";
import rightlogo from "./../../public/images/right_logo.svg";
import iclock from "./../../public/images/lock.svg";
import cardsm from "./../../public/images/cards-m.png";
import cardsv from "./../../public/images/cards-v.png";
import slashIcon from "./../../public/images/date-slash.png";

class PaymentStart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
	 return (
      <div className="form-pay-main mb-2">
        <div className="form-header">
          <div className="form-logo-left">
            <img src={leftlogo} alt="" />
          </div>
          <div className="form-logo-right">
            <img src={rightlogo} alt="" />
          </div>
        </div>
        <div className="form-payment text-left">
          <div className="secure-text">
            <span className="float-left">
              <img src={iclock} alt="" className="iclock" />
            </span>
            <h1>Secure Payment Info</h1>
            <h6>This is a secure 128-bit SSL Encrypted payment.</h6>
          </div>
          <div className="payment-cards">
            <input
              type="radio"
              name="radiog_lite"
              id="radio1"
              className="css-checkbox"
            />
            <label htmlFor="radio1" className="css-label radGroup1 radGroup1" />
            <span className="cards-type">
              <a href="javas">
                {" "}
                <img src={cardsm} alt="" />
              </a>
              <a href="javas">
                {" "}
                <img src={cardsv} alt="" />
              </a>
            </span>
            <div className="cards-form">
              <div className="form-group">
                <label>Name as it appears on the card</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter email"
                />
              </div>
              <div className="form-group">
                <label>Card Number (16 digits on the front of the card)</label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="XOXO - XOXO - XOXO - XOXO"
                />
              </div>
              <div className="row">
                <div className="col-8">
                  <label>Expiration date</label>
                  <div className="formdd_m">
                    <select className="form-control">
                      <option value="11">Month</option>
                    </select>
                  </div>
                  <span>
                    <img src={slashIcon} alt="" />{" "}
                  </span>
                  <div className="formdd_y">
                    <select className="form-control">
                      <option value="1988">Year</option>
                    </select>
                  </div>
                </div>
                <div className="col-4 pl-0">
                  <label className="text-left">CVV2/CVC2</label>
                  <input
                    type="text"
                    className="form-control float-right"
                    placeholder=""
                  />
                  <span>
                    <label>
                      The last 3 digits displayed on the back of your card
                    </label>
                  </span>
                </div>
              </div>

              <div className="form-group mt-4">
                <button className="btn btn-block btn-charge">
                  You will be charged <strong>AED 50</strong>
                </button>
              </div>
              <div className="form-group mt-4">
                <button className="btn btn-block btn-complete-transaction">
                  Complete Transaction
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
	}
}

export default PaymentStart;
