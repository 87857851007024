import React from "react";
import Slider from "rc-slider";
import _ from "lodash";
import "rc-slider/assets/index.css";
import "rc-slider/assets/index.css";
import "rc-tooltip/assets/bootstrap.css";
import "./range-slider.scss";
import { guid, decodeString, getTime, textToLink } from "./../utils/Utility";


import Tooltip from "rc-tooltip";
const Handle = Slider.Handle;

const handle = props => {
  const { value, dragging, index, ...restProps } = props;
  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </Tooltip>
  );
};

class RangeSlider extends React.Component {
  range = 'Mild';
  temparray = [];
  constructor(props) {
    super(props);
    this.state = {
      isValueSend: false,
      
    };
  }

  componentDidMount() {}
  componentDidUpdate() {}

  onSliderChange = value => {
    this.setState({
      value,
    });
  };
  onAfterChange = value => {
    
  };
  sendRange() {
    if(!(_.get(this, "state.isValueSend", false))) {
			this.setState({
				isValueSend: true
			}, () => {
        // this.props.formReply(_.get(this, "state.value", 0)+"");
        this.props.maskReply(_.get(this, "state.value", 0)+"", '/submit_severe_scale{"severe_scale": "'+_.get(this, "state.value", 0)+'"}')
			});
		}
  }

  renderStatus() {
    this.temparray = [];
    if (this.state.value < 3)
    {
      this.range = "Mild"
      this.temparray.push(this.state.value);
      this.temparray.push(this.range);
      return <div className="moderate">Mild</div>;
    }
    else if (this.state.value < "7"){
      this.range = "Moderate"
      this.temparray.push(this.state.value);
      this.temparray.push(this.range);
      return <div className="moderate">Moderate</div>;
    }
    else if (this.state.value >= "7"){
      this.range = "Severe/Unbearable"
      this.temparray.push(this.state.value);
      this.temparray.push(this.range);
      return <div className="moderate">Severe/Unbearable</div>;
    }
    return <div className="moderate">&nbsp;</div>;
  }
  
  
  render() {
    const isFormSubmit = _.get(this, "state.isValueSend", false);
    return (      
      <div className="range-slider">
        <Slider
          disabled={
            _.get(this, "props.disablebutton", true)
              ? "disabled"
              : ""
          }
          allowCross={false}
          pushable={true}
          dots
          min={_.get(this, "props.chat.data.min", 0)}
          max={_.get(this, "props.chat.data.max", 10)}
          step={_.get(this, "props.chat.data.step", 1)}
          included={true}
          value={this.state.value}
          onChange={this.onSliderChange}
          onAfterChange={this.onAfterChange}
          disabled={isFormSubmit}
          handle={handle}
          tipProps={{ visible: true }}
        />

        {  this.renderStatus() }
        <div className="submit-box" onClick={ () => this.sendRange()}>
          <i className="fa fa-angle-right" />
        </div>
      </div>
    );
  }
}

export default RangeSlider;
