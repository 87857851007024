import React from "react";
import { guid, decodeString, getTime, textToLink } from "./../utils/Utility";
import _ from "lodash";
import "./quick-reply.scss";
import { ENVIORMENT } from "./../../env";
import { Translate } from "./../Language/Translate";

class MultiSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formSubmitted: false,
      activeIndex: -1,
      selectedOptionsValues: [],
      payload: [],
      time: getTime()
    };
  }

  handleClick(quickReply, index) {
    if (
      _.get(this, "state.activeIndex", -1) === -1 &&
      _.get(this, "props.chat.type", "") !== "multi_select"
    ) {
      this.props.multiLangQuickReply(quickReply);
      if (_.get(this, "props.chat.type", "") === "language_selection") {
        this.props.changeLanguage(quickReply.payload);
      }
      _.set(this, "props.chat.quick_replies", []);
      this.setState({ activeIndex: index });
    }

    if (_.get(this, "props.chat.type", "") === "multi_select") {
      let selectedOptionsValues = _.get(
        this,
        "state.selectedOptionsValues",
        []
      );
      let payload = _.get(
        this,
        "state.payload",
        []
      );

      if (
        _.findIndex(_.get(this, "state.selectedOptionsValues", []), [
          "payload",
          quickReply.payload
        ]) > -1
      ) {
        selectedOptionsValues.splice(
          _.findIndex(_.get(this, "state.selectedOptionsValues", []), [
            "payload",
            quickReply.payload
          ]),
          1
        );
      } else {
        selectedOptionsValues.push(quickReply);
        payload.push(quickReply.payload);
      }
      this.setState({
        selectedOptionsValues: selectedOptionsValues,
        payload: payload
      });
    }
  }

  sendMultipleOptions() {
    
    if (_.get(this, "state.selectedOptionsValues", []).length > 0) {
        
      
      this.setState({
        formSubmitted: true
      }, () => {
        this.props.multiLangQuickReply(
          JSON.stringify(_.get(this, "state.selectedOptionsValues", []))
        )
      }); 

      _.set(this, "props.chat.quick_replies", []);
    }
  }

  getClass(quickReply) {
    if (_.get(this, "props.chat.type") === "multi_select") {
      if (
        _.findIndex(_.get(this, "state.selectedOptionsValues", []), [
          "payload",
          quickReply.payload
        ]) >= 0
      ) {
        return "btn-bbl";
      }
      return "btn-border-bbl";
    }
    return this.state.activeIndex === quickReply ? "btn-bbl" : "btn-border-bbl";
  }

  generateQuickReply(quickReply, i) {
    const guId = guid();
    if (
      quickReply.title == "Restart Session" ||
      quickReply.title == "End Session"
    ) {
      console.log("inside");
      return (
        <button
          key={guId}
          index={i}
          type="button"
          onClick={() => this.handleClick(quickReply, i)}
          className={
            "btn btn-sm m-1 " +
            this.getClass(quickReply) +
            (_.get(this, "props.isRTLFlow", false) === true &&
            _.get(this, "props.chat.font", "") !== "neutral"
              ? " font-arabic-light "
              : "")
          }
        >
          {decodeString(_.get(quickReply, "title", ""))}
        </button>
      );
    }  else {
      return (
        <button
          disabled={_.get(this, "props.disablebutton", true) ? "disabled" : ""}
          key={guId}
          index={i}
          type="button"
          onClick={() => this.handleClick(quickReply, i)}
          className={
            "btn btn-sm m-1 " +
            this.getClass(quickReply) +
            (_.get(this, "props.isRTLFlow", false) === true &&
            _.get(this, "props.chat.font", "") !== "neutral"
              ? " font-arabic-light "
              : "")
          }
        >
          {decodeString(_.get(quickReply, "title", ""))}
        </button>
      );
    }
  }

  render() {
    const isformSubmitted = _.get(this, "state.formSubmitted", false);
    
    let opts = {};
    if (isformSubmitted) {
      opts["disabled"] = "disabled";
    }
    const quickReplies = _.get(this, "props.chat.quick_replies", []);
    const time = this.state.time;
    return (
      <div className="bot">
        <div className="bot-avatar">
          <img
            src={require("./../../public/images/bot-avatar-" +
              _.get(this, "props.avatar", "") +
              ".png")}
            alt=""
          />
          <span className="status-online"> </span>
        </div>
        <div className="bot-msg">
          <div className="chat-time">{time}</div>
          <p>
            <span
              className={
                "float-left " +
                ((_.get(this, "props.isRTLFlow", false) === true && (_.get(this, "props.chat.font", "") !== "neutral"))
                  ? " font-arabic-light "
                  : "")
              }
              dangerouslySetInnerHTML={{
                __html: textToLink(
                  decodeString(_.get(this, "props.chat.text", ""))
                )
              }}
            />
          </p>

          <span className="float-left" style={{ clear: "both" }} />
          {quickReplies.length > 0 && (
            <span className="float-left mt-1">
              {quickReplies.map((quickReply, i) =>
                this.generateQuickReply(quickReply, i)
              )}
            </span>
          )}
          {_.get(this, "props.chat.type", "") === "multi_select" && (
            //(_.get(this, "state.selectedOptionsValues", []).length > 0) &&
            //(_.get(this, "props.chat.quick_replies", []).length > 0)
            <div>
              <span className="float-left" style={{ clear: "both" }} />
              {!isformSubmitted ? (
                                      <button
                  disabled={
                    _.get(this, "props.disablebutton", true)
                      ? "disabled"
                      : ""
                  }
                                      {...opts}
                                      className={
                                        "btn btn-sm m-1 btn-confirm pull-right d-flex align-items-center " +
                                        ((_.get(this, "props.isRTLFlow", false) === true && (_.get(this, "props.chat.font", "") !== "neutral"))
                                          ? " font-arabic-light "
                                          : "")
                                      }
                                      onClick={() => {
                                        this.sendMultipleOptions();
                                      }}
                                    >
                                      {Translate("CONFIRM", _.get(this, "props.lang"))}
                                      <span className="d-flex align-items-center pl-1">
                                        <img
                                          src={
                                            ENVIORMENT.project === "al-ain"
                                              ? require(`./../../public/images/send.svg`)
                                              : require(`./../../public/images/send-medcare.svg`)
                                          }
                                          height="18px"
                                          alt=""
                                        />
                                      </span>
                                    </button>
                                    ) : (
                                            <h1></h1>
                                        )}

              
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default MultiSelect;
