import React from "react";
import "./al-alain.scss";
import _ from "lodash";
// components
import PaymentStart from "./PaymentStart";
import PaymentContract from "./PaymentContract";

class AlAlain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {  
		if (_.get(this, "props.chat.type", "") === "alain_payment_form") {
			return <PaymentStart chat={this.props.chat} />;
		}
		if (_.get(this, "props.chat.type", "") === "edit_contract_form") {
			return <PaymentContract chat={this.props.chat} />;
		}    
  }
}
export default AlAlain;
